import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import ArticleOutlined from "@mui/icons-material/ArticleOutlined";

import TitlePage from "pages/components/TitlePage";
import NothingResource from 'pages/components/NothingResource';
import Loading from 'pages/components/Loading';
import SlideResources from './SlideResources';

import useDataContext from "context/data.hook";

import './styles.scss';

export default function LearningResources() {
    const [loadingResources, setLoadingResources] = useState(false);
    const { learningResources } = useDataContext();

    useEffect(() => {
        setLoadingResources(true);
        
        setTimeout(() => setLoadingResources(false), learningResources?.length > 0 ? 1000 : 3500);
        // eslint-disable-next-line
    }, []);

    if (loadingResources) {
        return <Loading />
    }

    return (
        <Box>
            <TitlePage icon={<ArticleOutlined />} title="Recursos de Aprendizagem" />
            
            {
                Array.isArray(learningResources) && learningResources.length > 0 ? (
                    <SlideResources resources={learningResources} placeCard="aprendizagem"/>
                ) : (
                    <NothingResource
                        title="Não foi realizado nenhum recurso de aprendizagem."
                        subtitle="Mas logo será atualizado"
                    />
                )
            }
        </Box>
    )
}