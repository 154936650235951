import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import useDataContext from 'context/data.hook';

import './styles.scss';

export default function NotFound() {
    const navigate = useNavigate();
    const { theme } = useDataContext();

    return (
        <Box className="area_not_found">
            <Box component="div">
                <Typography className={`main_not_found ${theme}`}>
                    <span>4</span>
                    <span>0</span>
                    <span>4</span>
                </Typography>
                <Typography className={`title_not_found ${theme}`}>Oh, Não! Esta página não foi encontrada</Typography>

                <Typography className={`subtitle_not_found ${theme}`}>
                    Essa página que você navegou não foi encontrada, pode ser que a URL que fez você chegar até aqui não exista de fato.
                    Clique no botão de início para ser direcionado para <span>PÁGINA PRINCIPAL</span>.
                </Typography>

                <Button
                    variant="contained"
                    onClick={() => navigate('/')}
                >
                    Quero ir para a principal
                </Button>
            </Box>
        </Box>
    )
}