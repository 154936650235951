import { material_api } from 'services';
import { useMutation, useQuery } from 'react-query';
import Toastify from 'components/Toastify';

export function useGetSubject(idSubject: string) {
    return useQuery(
        ['subject', idSubject],
        () => getSubjectById(idSubject),
        {
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            retry: false,
        }
    )
}

export function useGetQuizEvaluation(idSubject: string) {
    return useQuery(
        ['discipline_quiz', idSubject],
        () => getQuizEvaluation(idSubject),
        {
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            retry: false,
        }
    )
}

export function usePostQuizDisciplineEvaluation() {
    return useMutation(
        async (values: {
            respostas: {},
            idSubject: string
        }) => await postQuizDisciplineEvaluates(values),
        {
            onSuccess: () => {
                Toastify({ message: 'Agradecemos por sua avaliação', type: 'success' });
            }
        }
    )
}

export async function getSubjectById(idSubject: string) {
    const { data } = await material_api.get(`disciplina/${idSubject}`);
    return data;
}

export async function getQuizEvaluation(idSubject: string) {
    if (!idSubject) {
        return {
            data: {}
        }
    }

    const { data } = await material_api.get(`disciplina/${idSubject}/avaliar`);
    return data;
}

export async function postQuizDisciplineEvaluates(values: { respostas: {}, idSubject: string }) {
    const { data } = await material_api.post(`disciplina/${values.idSubject}/avaliar`, values);
    return data;
}