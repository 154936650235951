import React, { useState } from 'react';
import parser from 'html-react-parser';

import Box from '@mui/material/Box';
import TitlePage from 'pages/components/TitlePage';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';

import SchoolOutlined from '@mui/icons-material/SchoolOutlined';
import DriveFileRenameOutlineOutlined from '@mui/icons-material/DriveFileRenameOutlineOutlined';

import TabPanel from './TabPanel';
import Notepad from './Notepad';

import useDataContext from 'context/data.hook';

import './styles.scss';

export default function StudyGuide() {
    const { unitySelected } = useDataContext();

    const [tabActive, setTabActive] = useState(0);

    const [openNotepad, setOpenNotepad] = useState(false);

    return (
        <Box>
            <TitlePage icon={<SchoolOutlined />} title="Guia de estudo"/>

            <Box className="area_study_guide">
                <Paper elevation={3} className="paper">
                    <Tabs
                        centered
                        textColor="primary"
                        indicatorColor="primary"
                        value={tabActive}
                        onChange={(e: React.SyntheticEvent, newValue) => setTabActive(newValue)}
                    >
                        <Tab label="A"/>
                        <Tab label="B" />
                        <Tab label="C" />
                    </Tabs>

                    <TabPanel value={tabActive} index={0}>
                        {parser(unitySelected?.guia_de_estudo_a)}
                    </TabPanel>

                    <TabPanel value={tabActive} index={1}>
                        {parser(unitySelected?.guia_de_estudo_b)}
                    </TabPanel>

                    <TabPanel value={tabActive} index={2}>
                        {parser(unitySelected?.guia_de_estudo_c)}
                    </TabPanel>

                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<DriveFileRenameOutlineOutlined />}
                        onClick={() => setOpenNotepad(true)}
                    >
                        Responda no bloco de notas
                    </Button>
                </Paper>
            </Box>

            <Notepad
                open={openNotepad}
                setOpen={setOpenNotepad}
            />
        </Box>
    )
}