import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './styles.scss';

interface IEmojisEvaluate {
    rate: 'Ótima' | 'Boa' | 'Regular' | 'Ruim' | 'Péssima' | '',
    setRate: (position: 'rate' | 'feedback', value: string) => void;
}

export default function EmojisEvaluate({ rate, setRate }: IEmojisEvaluate) {
    return (
        <Box className="area_emojis">
            <Box component="div" className={`excellent ${rate === 'Ótima' && 'active'}`} onClick={() => setRate('rate', 'Ótima')}>
                <span>🤩</span>
                <Typography variant="h6">Ótimo</Typography>
            </Box>

            <Box component="div" className={`well ${rate === 'Boa' && 'active'}`} onClick={() => setRate('rate', 'Boa')}>
                <span>😃</span>
                <Typography variant="h6">Bom</Typography>
            </Box>

            <Box component="div" className={`regular ${rate === 'Regular' && 'active'}`} onClick={() => setRate('rate', 'Regular')}>
                <span>🙂</span>
                <Typography variant="h6">Regular</Typography>
            </Box>

            <Box component="div" className={`bad ${rate === 'Ruim' && 'active'}`} onClick={() => setRate('rate', 'Ruim')}>
                <span>😓</span>
                <Typography variant="h6">Ruim</Typography>
            </Box>

            <Box component="div" className={`very_bad ${rate === 'Péssima' && 'active'}`} onClick={() => setRate('rate', 'Péssima')}>
                <span>😢</span>
                <Typography variant="h6">Péssima</Typography>
            </Box>
        </Box>
    )
}