import { Routes as Switch, Route } from 'react-router-dom';

import Login from 'pages/Login';
import NotFound from 'pages/NotFound';

export default function NotAuthenticated() {
    return (
        <Switch>
            <Route path='/:idSubject' element={<Login />}/>
            <Route path='/:idSubject/faculdade_5_de_julho' element={<Login typeTheme='fal' />}/>
            <Route path='/:idSubject/:tokenTutor' element={<Login />}/>
            <Route path='/:idSubject/ecossistema/:acess' element={<Login />}/>
            <Route path='/:idSubject/faculdade_5_de_julho/:tokenTutor' element={<Login typeTheme='fal' />}/>
            <Route path='/:idSubject/faculdade_5_de_julho/ecossistema/:acess' element={<Login typeTheme='fal' />}/>
            
            <Route path='*' element={<NotFound />}/>
        </Switch>

    )
}