import { FormEvent, useEffect, useState } from 'react';

import RichTextEditor from 'react-rte';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import DriveFileRenameOutlineOutlined from '@mui/icons-material/DriveFileRenameOutlineOutlined';

import { NotepadProps } from './types';

import useDataContext from 'context/data.hook';
import { useGetNotepad, RegisterNotepad } from 'hooksQuerys/useUnitys';

import './styles.scss';

export default function Notepad({ open, setOpen }: NotepadProps) {
    const { theme, unitySelected } = useDataContext();
    const { data, isSuccess } = useGetNotepad(unitySelected?.id);
    const { mutate, isLoading } = RegisterNotepad();

    const [content, setContent] = useState(RichTextEditor.createEmptyValue());

    const onSave = (e: FormEvent) => {
        e.preventDefault();

        let whatAction: 'create' | 'update' = 'create';

        if (!!data && data.bloco.id) {
            whatAction = 'update';
        }

        mutate({
            whatAction,
            idUnity: unitySelected?.id,
            idNotepad: data?.bloco?.id,
            texto: content.toString('markdown')
        });
    }

    useEffect(() => {
        if (isSuccess) {
            setContent(RichTextEditor.createValueFromString(data?.bloco?.texto ?? '', 'markdown'));
        }
    }, [isSuccess, data])

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle className={`dialog_title_notepad ${theme}`}>
                <DriveFileRenameOutlineOutlined />
                <Typography className="text_title">Minhas Anotações</Typography>
            </DialogTitle>
            
            <form onSubmit={onSave}>
                <DialogContent dividers>
                    <RichTextEditor
                        value={content}
                        onChange={setContent}
                        autoFocus
                        editorStyle={{ height: '20rem'}}
                        placeholder="Digite aqui suas anotações..."
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="success"
                        type="submit"
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress style={{ width: 20, height: 20 }} /> : 'Salvar'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}