import { FormEvent, useState } from 'react';
import { useCookies } from 'react-cookie';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Toastify from 'components/Toastify';

import useDataContext from 'context/data.hook';
import { authenticationMyLibrary } from 'hooksQuerys/useLogin';

import './styles.scss';
interface IDialogAuthMyLibraryProps {
    open: boolean;
    linkAccess: string;
    setOpen: (e: boolean) => void;
}

export default function DialogAuthMyLibrary({
    open,
    linkAccess,
    setOpen
}: IDialogAuthMyLibraryProps) {
    const setCookie = useCookies()[1];
    const { theme, ra } = useDataContext();
    const [password, setPassword] = useState('');
    const [isPassword, setIsPassword] = useState(true);

    const onClosed = () => {
        setOpen(false);
        setPassword('');
        setIsPassword(true);
    }

    const onConfirm = (e: FormEvent) => {
        e.preventDefault();

        if (ra && password) {
            authenticationMyLibrary({ username: ra, password }).then(({data}) => {
                if (data.success) {
                    const dateWithPlusFourHours = new Date();
                    dateWithPlusFourHours.setHours(dateWithPlusFourHours.getHours() + 4);

                    const mywindow = window.open(data.url, "AutenticacaoMinhaBiblioteca","width=1,height=1,top=10000, left=10000");

                    setTimeout(() => {
                        mywindow?.close();
                        window.open(linkAccess, '_blank');

                        const save = window.btoa(JSON.stringify({ myLibrary_auth: true }));
                        setCookie("a_mb", save, {
                            expires: dateWithPlusFourHours,
                        });
                    }, 2000);
                }
            }).catch(console.log)
        } else {
            Toastify({ message: 'Por favor preencha todos campos.', type: 'warn' });
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullWidth
            maxWidth="sm"
        >
            <form onSubmit={onConfirm}>
                <DialogTitle className={`dialog_title_auth_my_library ${theme}`}>
                    <Typography>Acessar Minha Biblioteca</Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <Typography className="info_confirmation">Para confirmar o acesso ao material de estudo, por favor confirme a sua senha.</Typography>

                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Confirme sua senha:</InputLabel>
                        <OutlinedInput
                            fullWidth
                            label="Confirme sua senha:"
                            required
                            placeholder="Digite sua senha aqui..."
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setIsPassword(!isPassword)}
                                        edge="end"
                                    >
                                        {isPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            type={isPassword ? 'password' : 'text'}
                        />
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="success"
                        type="submit"
                    >Confirmar</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}