import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import ArticleOutlined from '@mui/icons-material/ArticleOutlined';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';

import TitlePage from 'pages/components/TitlePage';

import useDataContext from 'context/data.hook';

import './styles.scss';

export default function HowToStudy() {
    const { subject } = useDataContext();

    return (
        <Box className="area_how_to_study">
            <TitlePage icon={<ArticleOutlined />} title="Como estudar" />

            <Box className="area_banner" style={{ backgroundImage: `url('${subject?.capa}')` }}>
                <Typography variant="subtitle1">{subject?.nome}</Typography>
            </Box>

            <Box className="infos">
                <Typography variant="subtitle1">Iniciando a trilha de aprendizagem</Typography>

                <Alert icon={<CheckCircleOutline />} severity="info">
                    <AlertTitle>Curadoria digital</AlertTitle>

                    Os professores responsáveis pela organização e seleção dos conteúdos desta disciplina são profissionais reconhecidos por seu mérito
                    na área específica desse conhecimento e responsáveis pela seleção de fontes de pesquisas relevantes sobre os temas que formam as
                    trilhas de aprendizagem, que norteará você na experiência de uma leitura guiada para sua aprendizagem.
                </Alert>

                <Alert icon={<CheckCircleOutline />} severity="info">
                    <AlertTitle>Trilhas de aprendizagem</AlertTitle>

                    Seguindo este roteiro de estudos, você encontra a informação necessária para que possa aprender os conteúdos e conseguir sucesso
                    nessa disciplina.
                </Alert>

                <Alert icon={<CheckCircleOutline />} severity="info">
                    <AlertTitle>Autonomia e controle</AlertTitle>

                    Não esqueça! O estudo guiado exige a autorresponsabilidade do estudante pelo seu percurso de aprendizagem, mas você será acompanhado,
                    permanente por professores e tutores para colaborar no seu percurso de aprendizagem.
                </Alert>
            </Box>
        </Box>
    )
}