import { lazy, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import ArticleOutlined from '@mui/icons-material/ArticleOutlined';
import CheckCircle from '@mui/icons-material/CheckCircle';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';
import SchoolOutlined from '@mui/icons-material/SchoolOutlined';
import EmojiObjectsOutlined from '@mui/icons-material/EmojiObjectsOutlined';
import CloudUploadOutlined from '@mui/icons-material/CloudUploadOutlined';
import WbIncandescentOutlined from '@mui/icons-material/WbIncandescentOutlined';
import ThumbUpOutlined from '@mui/icons-material/ThumbUpOutlined';
import BallotOutlined from '@mui/icons-material/BallotOutlined';
import HelpOutline from '@mui/icons-material/HelpOutline';
import BookOutlined from '@mui/icons-material/BookOutlined';
import ExitToApp from '@mui/icons-material/ExitToApp';
import StarBorder from '@mui/icons-material/StarBorder';

import Loading from 'pages/components/Loading';

import useDataContext from 'context/data.hook';

import { INavigationProps } from './types';

import './styles.scss';

const List = lazy(() => import('@mui/material/List'));
const ListItem = lazy(() => import('@mui/material/ListItem'));
const ListItemButton = lazy(() => import('@mui/material/ListItemButton'));
const ListItemIcon = lazy(() => import('@mui/material/ListItemIcon'));
const ListItemText = lazy(() => import('@mui/material/ListItemText'));
const Toolbar = lazy(() => import('@mui/material/Toolbar'));
const Box = lazy(() => import('@mui/material/Box'));
const LinearProgress = lazy(() => import('@mui/material/LinearProgress'));
const Typography = lazy(() => import('@mui/material/Typography'));
const FormControl = lazy(() => import('@mui/material/FormControl'));
const InputLabel = lazy(() => import('@mui/material/InputLabel'));
const Select = lazy(() => import('@mui/material/Select'));
const MenuItem = lazy(() => import('@mui/material/MenuItem'));
const Divider = lazy(() => import('@mui/material/Divider'));
const Button = lazy(() => import('@mui/material/Button'));
const DialogAssessment = lazy(() => import('./DialogAssessmentGuidelines'));

export default function ListMenuNavigation() {
    const {
        theme,
        subject,
        unitys,
        unitySelected,
        setUnitySelected,
        videos,
        pratic_videos,
        script_pratical,
        depeeningKnowledge,
        learningResources,
        setActivedStep,
        onHandleUpdateLogs,
        logs,
        disciplineProgress
    } = useDataContext();
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();

    const [visibleHowStudy, setVisibleHowStudy] = useState(true);
    const [visibleDisciplineEvaluation, setVisibleDisciplineEvaluation] = useState(false);
    const [openAssessmentGuidelines, setOpenAssessmentGuidelines] = useState(false);

    /**
     * Function for verify if the item is active
     */
    const isItemNavigateActive = (route: string): boolean => {
        if (location.pathname === route) {
            return true;
        }

        return false
    }

    const navigation = ({
        url,
        pathActived,
        subpathActived = 0,
        typeLog,
        resource,
        changed = false,
        unity
    }: INavigationProps) => {
        navigate(url);

        setActivedStep({
            path: pathActived,
            subpath: subpathActived
        });
        
        sessionStorage.setItem('@md_AS', window.btoa(JSON.stringify({ path: pathActived, subpath: subpathActived })));
        
        if (typeLog) {
            if (
                (typeLog === 'Videoaulas' ||
                typeLog === 'VídeosParaAulaPrática') &&
                !resource
            ) {
                return;
            }

            setTimeout(() => {
                onHandleUpdateLogs({
                    typeLog,
                    resource,
                    unity: changed ? unity : unitySelected
                });
            }, 500);
        }
    }

    const signOut = () => {
        sessionStorage.removeItem('@md_S');
        sessionStorage.removeItem('@md_AS');
        queryClient.invalidateQueries('session');
        
        setTimeout(() => {
            window.location.href = `/${subject?.id}`;
        }, 100);
    }

    return (
        <>
            <List style={{ maxWidth: '20rem' }}>
                <Toolbar style={{ minWidth: '20rem' }}/>

                <ListItem>
                    <Box className="area_nav_info">
                        <Box>
                            <Button
                                startIcon={<ExitToApp />}
                                variant="outlined"
                                onClick={signOut}
                            >
                                Sair
                            </Button>

                            <Typography variant="subtitle1">{subject?.nome}</Typography>
                        </Box>

                        <Box>
                            <LinearProgress
                                className="progress"
                                variant="determinate"
                                value={disciplineProgress}
                                color="success"
                            />
                            <Typography variant="subtitle2" marginBottom={2}>{disciplineProgress}% completo</Typography>

                            {
                                visibleDisciplineEvaluation && (
                                    <Button
                                        startIcon={<StarBorder />}
                                        variant="contained"
                                        fullWidth
                                        color="secondary"
                                        onClick={() => navigation({ url: '/avaliar-disciplina', pathActived: -1})}
                                    >
                                        Avaliar Disciplina
                                    </Button>
                                )
                            }
                        </Box>
                    </Box>
                </ListItem>

                <Divider />

                <ListItem>
                    <Box className="area_nav_info">
                        {
                            unitySelected?.id ? (
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Unidade atual:</InputLabel>

                                    <Select
                                        label="Unidade atual:"
                                        value={unitySelected?.id}
                                        onChange={(e) => {
                                            const unityChanged = unitys.filter((unity) => unity.id === e.target.value)[0];
                                            
                                            setUnitySelected(unityChanged);

                                            if (unitys.indexOf(unityChanged) === 0) {
                                                navigation({
                                                    url: '/como-estudar',
                                                    pathActived: 0,
                                                    typeLog: "ComoEstudar",
                                                    changed: true,
                                                    unity: unityChanged
                                                });
                                                setVisibleHowStudy(true);
                                                setVisibleDisciplineEvaluation(false);
                                            } else {
                                                setVisibleHowStudy(false);
                                                navigation({
                                                    url: 'resumo-unidade',
                                                    pathActived: 1,
                                                    typeLog: "ResumoDaUnidade",
                                                    changed: true,
                                                    unity: unityChanged
                                                });

                                                if (unitys.indexOf(unityChanged) === (unitys.length - 1)) {
                                                    setVisibleDisciplineEvaluation(true);
                                                } else {
                                                    setVisibleDisciplineEvaluation(false);
                                                }
                                            }

                                        }}
                                    >
                                        {
                                            Array.isArray(unitys) && unitys?.map((unity) => (
                                                <MenuItem value={unity.id} key={unity.id}>{unity.titulo_unidade}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            ) : (
                                <Loading />
                            )
                        }
                        

                        <Typography variant="subtitle2">{unitySelected?.titulo_unidade}</Typography>
                    </Box>
                </ListItem>

                <Divider />

                {/* Navigations */}
                <ListItem className="item_nav optional">
                    <ListItemButton onClick={() => setOpenAssessmentGuidelines(true)}>
                        <ListItemIcon>
                            <HelpOutline />

                            <BookOutlined />
                        </ListItemIcon>

                        <ListItemText primary="Orientações para Avaliações" />
                    </ListItemButton>
                </ListItem>

                {
                    visibleHowStudy && (
                        <ListItem className={`item_nav ${isItemNavigateActive('/como-estudar') ? 'active' : ''} ${theme}`}>
                            <ListItemButton onClick={() => navigation({ url: '/como-estudar', pathActived: 0})}>
                                <ListItemIcon>
                                    {
                                        logs?.log_info['ComoEstudar'] ? <CheckCircle /> : <RadioButtonUnchecked />
                                    }

                                    <ArticleOutlined />
                                </ListItemIcon>

                                <ListItemText primary="Como estudar" />
                            </ListItemButton>
                        </ListItem>
                    )
                }

                <ListItem className={`item_nav ${isItemNavigateActive('/resumo-unidade') ? 'active' : ''} ${theme}`}>
                    <ListItemButton onClick={() => navigation({ url: '/resumo-unidade', pathActived: 1, typeLog: "ResumoDaUnidade"})}>
                        <ListItemIcon>
                            {
                                logs?.log_info['ResumoDaUnidade'] ? <CheckCircle /> : <RadioButtonUnchecked />
                            }

                            <ArticleOutlined />
                        </ListItemIcon>

                        <ListItemText primary="Resumo da Unidade" />
                    </ListItemButton>
                </ListItem>

                <ListItem className={`item_nav ${isItemNavigateActive('/recursos-aprendizagem') ? 'active' : ''} ${theme}`}>
                    <ListItemButton onClick={() => navigation({ url: '/recursos-aprendizagem', pathActived: 2})}>
                        <ListItemIcon> 
                            {
                                logs?.log_info['RecursosDeAprendizagem'] &&
                                (
                                    logs?.log_info['RecursosDeAprendizagem']?.length
                                    === learningResources?.length
                                ) ? <CheckCircle /> : <RadioButtonUnchecked />
                            }

                            <ArticleOutlined />
                        </ListItemIcon>

                        <ListItemText primary="Recursos de aprendizagem" />
                    </ListItemButton>
                </ListItem>

                <Divider />

                {/* Será liberado após a atualização de agendamentos de videos, dependendo da aprovacao da gestao pedagogica */}
                {/* <ListItem>
                    <Typography className="title_navigation" variant="subtitle2">Hotpoint</Typography>
                </ListItem>

                <Divider />

                <ListItem className={`item_nav ${isItemNavigateActive('/como-estudar') ? 'active' : ''}`}>
                    <ListItemButton>
                        <ListItemIcon>
                            <RadioButtonUnchecked />

                            <PlayCircleOutline />
                        </ListItemIcon>

                        <ListItemText primary="Vesículas primordiais" />
                    </ListItemButton>
                </ListItem>

                <ListItem className={`item_nav ${isItemNavigateActive('/como-estudar') ? 'active' : ''}`}>
                    <ListItemButton>
                        <ListItemIcon>
                            <RadioButtonUnchecked />

                            <PlayCircleOutline />
                        </ListItemIcon>

                        <ListItemText primary="Canais iônicos" />
                    </ListItemButton>
                </ListItem>

                <ListItem className={`item_nav ${isItemNavigateActive('/como-estudar') ? 'active' : ''}`}>
                    <ListItemButton>
                        <ListItemIcon>
                            <RadioButtonUnchecked />

                            <PlayCircleOutline />
                        </ListItemIcon>

                        <ListItemText primary="Fenda sináptica" />
                    </ListItemButton>
                </ListItem>

                <ListItem className={`item_nav ${isItemNavigateActive('/como-estudar') ? 'active' : ''}`}>
                    <ListItemButton>
                        <ListItemIcon>
                            <RadioButtonUnchecked />

                            <PlayCircleOutline />
                        </ListItemIcon>

                        <ListItemText primary="Neurotransmissores" />
                    </ListItemButton>
                </ListItem>

                <Divider /> */}

                <ListItem>
                    <Typography className="title_navigation" variant="subtitle2">Videoaulas</Typography>
                </ListItem>

                <Divider />

                {
                    !!videos ? videos?.map((video: any, index) => (
                        <ListItem className={`item_nav ${isItemNavigateActive(`/videoaulas/${video?.id}`) ? 'active' : ''} ${theme}`} key={video?.id}>
                            <ListItemButton
                                onClick={() => 
                                    navigation({
                                        url: `videoaulas/${video?.id}`,
                                        pathActived: 3,
                                        subpathActived: index,
                                        typeLog: "Videoaulas",
                                        resource: video?.id
                                    })}>
                                <ListItemIcon>
                                    {
                                        Array.isArray(logs?.log_info['Videoaulas']) &&
                                        logs?.log_info['Videoaulas'].includes(video?.id) ? <CheckCircle /> : <RadioButtonUnchecked />
                                    }

                                    <PlayCircleOutline />
                                </ListItemIcon>

                                <ListItemText primary={video.titulo_video} />
                            </ListItemButton>
                        </ListItem>
                    )) : (
                        <ListItem className="item_nav">
                            <ListItemButton disabled>
                                <ListItemText className="void" primary="( Nenhum vídeo encontrado )" />
                            </ListItemButton>
                        </ListItem>
                    )
                }

                <Divider />

                {/* Será liberado após a atualização de agendamentos de videos, dependendo da aprovacao da gestao pedagogica */}
                {/* <ListItem>
                    <Typography className="title_navigation" variant="subtitle2">Practical Cases</Typography>
                </ListItem>

                <Divider />

                <ListItem className={`item_nav ${isItemNavigateActive('/como-estudar') ? 'active' : ''}`}>
                    <ListItemButton>
                        <ListItemIcon>
                            <RadioButtonUnchecked />

                            <PlayCircleOutline />
                        </ListItemIcon>

                        <ListItemText primary="Caso 1" />
                    </ListItemButton>
                </ListItem>

                <Divider /> */}

                <ListItem>
                    <Typography className="title_navigation" variant="subtitle2">Interagindo</Typography>
                </ListItem>

                <Divider />

                <ListItem className={`item_nav ${isItemNavigateActive('/guia-estudos') ? 'active' : ''} ${theme}`}>
                    <ListItemButton onClick={() => navigation({ url: '/guia-estudos', pathActived: 4, typeLog: "GuiaDeEstudos"})}>
                        <ListItemIcon>
                            {
                                logs?.log_info['GuiaDeEstudos'] ? <CheckCircle /> : <RadioButtonUnchecked />
                            }

                            <SchoolOutlined />
                        </ListItemIcon>

                        <ListItemText primary="Guia de estudos" />
                    </ListItemButton>
                </ListItem>

                <ListItem className={`item_nav ${isItemNavigateActive('/partilhando-ideias') ? 'active' : ''} ${theme}`}>
                    <ListItemButton onClick={() => navigation({ url: '/partilhando-ideias', pathActived: 5, typeLog: "PartilhandoIdeias"})}>
                        <ListItemIcon>
                            {
                                logs?.log_info['PartilhandoIdeias'] ? <CheckCircle /> : <RadioButtonUnchecked />
                            }

                            <EmojiObjectsOutlined />
                        </ListItemIcon>

                        <ListItemText primary="Partilhando ideias" />
                    </ListItemButton>
                </ListItem>

                <ListItem className={`item_nav ${isItemNavigateActive('/contribuindo-documento') ? 'active' : ''} ${theme}`}>
                    <ListItemButton onClick={() => navigation({ url: '/contribuindo-documento', pathActived: 6, typeLog: "ContribuaComUmDocumento"})}>
                        <ListItemIcon>
                            {
                                logs?.log_info['ContribuaComUmDocumento'] ? <CheckCircle /> : <RadioButtonUnchecked />
                            }

                            <CloudUploadOutlined />
                        </ListItemIcon>

                        <ListItemText primary="Contribua com um documento" />
                    </ListItemButton>
                </ListItem>
                
                {
                    Array.isArray(depeeningKnowledge) && (
                        <ListItem className={`item_nav ${isItemNavigateActive('/aprofundando-conhecimento') ? 'active' : ''} ${theme}`}>
                            <ListItemButton onClick={() => navigation({ url: '/aprofundando-conhecimento', pathActived: 7})}>
                                <ListItemIcon>
                                    {
                                        logs?.log_info['AprofundandoNoConhecimento'] &&
                                        (
                                            logs?.log_info['AprofundandoNoConhecimento']?.length
                                            === depeeningKnowledge?.length
                                        ) ? <CheckCircle /> : <RadioButtonUnchecked />
                                    }

                                    <WbIncandescentOutlined />
                                </ListItemIcon>

                                <ListItemText primary="Aprofundando o conhecimento" />
                            </ListItemButton>
                        </ListItem>
                    )
                }

                <ListItem className={`item_nav ${isItemNavigateActive('/avaliar-unidade') ? 'active' : ''} ${theme}`}>
                    <ListItemButton onClick={() => navigation({ url: '/avaliar-unidade', pathActived: 8, typeLog: "AvaliarUnidade"})}>
                        <ListItemIcon>
                            {
                                logs?.log_info['AvaliarUnidade'] ? <CheckCircle /> : <RadioButtonUnchecked />
                            }

                            <ThumbUpOutlined />
                        </ListItemIcon>

                        <ListItemText primary="Avaliar a unidade" />
                    </ListItemButton>
                </ListItem>

                <Divider />

                {/* Será liberado após a atualização de agendamentos de videos, dependendo da aprovacao da gestao pedagogica */}
                {/* <ListItem>
                    <Typography className="title_navigation" variant="subtitle2">Dúvidas Frequentes</Typography>
                </ListItem>

                <Divider />

                <ListItem className={`item_nav ${isItemNavigateActive('/como-estudar') ? 'active' : ''}`}>
                    <ListItemButton>
                        <ListItemIcon>
                            <RadioButtonUnchecked />

                            <PlayCircleOutline />
                        </ListItemIcon>

                        <ListItemText primary="O filamento proteico faz parte do citoesqueleto?" />
                    </ListItemButton>
                </ListItem>

                <ListItem className={`item_nav ${isItemNavigateActive('/como-estudar') ? 'active' : ''}`}>
                    <ListItemButton>
                        <ListItemIcon>
                            <RadioButtonUnchecked />

                            <PlayCircleOutline />
                        </ListItemIcon>

                        <ListItemText primary="A glândula prostática é endócrina ou exócrina?" />
                    </ListItemButton>
                </ListItem>

                <Divider /> */}

                {
                    (script_pratical || !!pratic_videos) && (
                        <>
                            <ListItem>
                                <Typography className="title_navigation" variant="subtitle2">Orientações para prática</Typography>
                            </ListItem>

                            <Divider />

                            <ListItem className={`item_nav ${isItemNavigateActive('/roteiro-pratica') ? 'active' : ''} ${theme}`}>
                                <ListItemButton onClick={() => navigation({ url: '/roteiro-pratica', pathActived: 9, typeLog: "RoteiroParaAulaPrática"})}>
                                    <ListItemIcon>
                                        {
                                            logs?.log_info['RoteiroParaAulaPrática'] ? <CheckCircle /> : <RadioButtonUnchecked />
                                        }

                                        <BallotOutlined />
                                    </ListItemIcon>

                                    <ListItemText primary="Roteiro para aula prática" />
                                </ListItemButton>
                            </ListItem>
                            
                            {
                                !!pratic_videos && pratic_videos?.map((praticVideo: any, index) => (
                                    <ListItem className={`item_nav ${isItemNavigateActive(`/aula-pratica/${praticVideo.id}`) ? 'active' : ''} ${theme}`}>
                                        <ListItemButton
                                            onClick={() =>
                                                navigation({
                                                    url: `/aula-pratica/${praticVideo.id}`,
                                                    pathActived: 10,
                                                    subpathActived: index,
                                                    typeLog: "VídeosParaAulaPrática",
                                                    resource: praticVideo.id
                                                })
                                            }
                                            >
                                            <ListItemIcon>
                                                {
                                                    Array.isArray(logs?.log_info['VídeosParaAulaPrática']) &&
                                                    logs?.log_info['VídeosParaAulaPrática'].includes(praticVideo?.id) ? <CheckCircle /> : <RadioButtonUnchecked />
                                                }

                                                <PlayCircleOutline />
                                            </ListItemIcon>

                                            <ListItemText primary={praticVideo.titulo_video} />
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            }       
                        </>
                    )
                }
            </List>

            <DialogAssessment
                open={openAssessmentGuidelines}
                setOpen={setOpenAssessmentGuidelines}
            />
        </>
    )
}