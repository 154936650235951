import { FormEvent, useState } from 'react';

import { useQueryClient } from 'react-query';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';

import Telegram from '@mui/icons-material/Telegram';

import Loading from 'pages/components/Loading';
import NothingResource from 'pages/components/NothingResource';
import Toastify from 'components/Toastify';

import { RegisterCommentContribute, useShowContributeDocument } from 'hooksQuerys/useUnitys';
import useDataContext from 'context/data.hook';

import { DialogCommentsProps } from './types';

import './styles.scss';

export default function DialogComments({ open, idContribute, setOpen }: DialogCommentsProps) {
    const queryClient = useQueryClient();
    const { theme, ra } = useDataContext();
    const { data, isLoading, refetch } = useShowContributeDocument(idContribute);

    const [reloadListContribute, setReloadListContribute] = useState(false);
    const [comment, setComment] = useState('');

    const { mutate, isLoading: isLoadingSaveComment } = RegisterCommentContribute();

    const onClosed = () => {
        if (reloadListContribute) {
            queryClient.invalidateQueries('contributes');
        }

        setOpen(false);
        setComment('');
        setReloadListContribute(false);
    }

    const onValidate = (e: FormEvent) => {
        e.preventDefault();

        if (!idContribute) {
            Toastify({ message: 'Preencha o campo de identificação do comentário.', type: 'warn'});
            return;
        }

        if (!comment) {
            Toastify({ message: 'Preencha o campo de comentário.', type: 'warn'});
            return;
        }

        if (comment.length > 245) {
            Toastify({ message: 'O comentário deve ter no máximo 245 caracteres.', type: 'warn'});
            return;
        }

        onSave();
    }

    const onSave = () => {
        mutate({ comentario: comment, idContribute});

        setTimeout(() => {
            refetch();
        }, 1000);
        
        setReloadListContribute(true);
        setComment('');
    }

    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle className={`dialog_title_comments ${theme}`}>
                Comentários
            </DialogTitle>

            <DialogContent dividers>
                {
                    isLoading ? (
                        <Loading />
                    ) : (
                        <Box className="area_comments">
                            {
                                Array.isArray(data.comentarios) && data.comentarios.length > 0 ? (
                                    <>
                                        {
                                            data.comentarios.map((infoComment: any) => (
                                                <Box key={infoComment.id} className={`area_comment ${ra === infoComment.ra_aluno && 'is_auth'}`}>
                                                    <Typography className="author">[aluno] - [RA]</Typography>

                                                    <Typography className={`comment ${ra === infoComment.ra_aluno && 'is_auth'}`}>{infoComment.comentario}</Typography>
                                                </Box>
                                            ))
                                        }
                                    </>
                                ) : (
                                    <NothingResource
                                        title="( Nenhum Comentário )"
                                        subtitle="Faça o primeiro comentário."
                                    />
                                )
                            }
                        </Box>
                    )
                }
            </DialogContent>

            <DialogActions className={`dialog_actions_comments ${theme}`}>
                <form onSubmit={onValidate}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Comentário:"
                        placeholder="Digite o seu comentário sobre o documento aqui..."
                        required
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        disabled={isLoading || isLoadingSaveComment}
                        error={comment.length > 245}
                        inputProps={{ "maxLength": 245 }}
                    />

                    <IconButton type="submit" disabled={isLoading || isLoadingSaveComment}>
                        {isLoadingSaveComment ? <CircularProgress style={{ width: 20, height: 20 }}/> : <Telegram />}
                    </IconButton>
                </form>
                <FormHelperText error={comment.length > 245}>
                    {comment.length} de no máximo 245 caracteres
                </FormHelperText>
            </DialogActions>
        </Dialog>
    )
}