import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import EmojiObjectsOutlined from '@mui/icons-material/EmojiObjectsOutlined';
import MessageOutlined from '@mui/icons-material/MessageOutlined';

import TitlePage from '../components/TitlePage';

import './styles.scss';
import useDataContext from 'context/data.hook';

export default function SharingIdeas() {
    const { subject } = useDataContext();

    const link = () => {
        window.open(subject.link_partilhando_ideias, '_blank');
    }

    return (
        <Box>
            <TitlePage icon={<EmojiObjectsOutlined />} title="Partilhando Ideias" />

            <Box className="area_sharing_ideas">
                <Typography variant="h3">
                    Sejam bem-vindos a este espaço de aprendizagem colaborativa.
                </Typography>

                <Typography variant="h3">
                    Neste fórum você terá a oportunidade de compartilhar suas anotações sobre as unidades de estudo, colaborar e interagir
                    com todos os estudantes dessa disciplina.
                </Typography>

                <Box component="div">
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<MessageOutlined />}
                        onClick={() => link()}
                    >
                        Fórum Partilhando Ideias
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}