import { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';

import Comment from '@mui/icons-material/Comment';

import RateContribution from '../RateContribution';

import { ContributionItemProps } from './types';

import './styles.scss';

export default function ContributionItem(props: ContributionItemProps) {
    const [openRate, setOpenRate] = useState(false);

    return (
        <Box className="contribution_item">
            <Typography variant="h2">{props.title}</Typography>
            <Typography variant="h3">{props.description}</Typography>

            <Button
                variant="contained"
                color="secondary"
                size="large"
                target="_blank"
                href={props.link}
            >Acessar</Button>

            <Box className="contribution_details">
                <Button
                    variant="text"
                    startIcon={<Comment />}
                    color="secondary"
                    size="large"
                    onClick={() => {
                        props.setOpenComments(true);
                        props.setIdContribute(props.idContribute)
                    }}
                >
                    Comentários ({props.numComments})
                </Button>

                <Typography variant="h6">RA: <span>{props.ra}</span></Typography>
                <Typography variant="h6">Disciplina: <span>{props.subject}</span></Typography>
                

                <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                    <Rating
                        value={props.rate}
                        precision={0.5}
                        readOnly
                        className='rating'
                    />

                    <Typography className="num_rate">{props.rate ? props.rate.toFixed(1).replace('.', ',') : '-.-'}</Typography>
                </Box>

                <Button
                    variant="outlined"
                    onClick={() => setOpenRate(true)}
                >Fazer Avaliação</Button>
            </Box>

            <RateContribution
                open={openRate}
                idContribute={props.idContribute}
                setOpen={setOpenRate}
            />
        </Box>
    )
}