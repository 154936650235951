import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

import Close from '@mui/icons-material/Close';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import { IDialogPdfReaderProps } from './types';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './styles.scss';

export default function DialogPdfReader({
    open,
    link,
    setOpen
}: IDialogPdfReaderProps) {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const onClosed = () => {
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullScreen
        >
            <DialogContent style={{ position: 'relative' }}>
                <Button
                    variant="contained"
                    onClick={onClosed}
                    startIcon={<Close />}
                    style={{ marginBottom: 10 }}
                >Fechar</Button>

                <Worker  workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                    <Viewer
                        fileUrl={link}
                        plugins={[defaultLayoutPluginInstance]}
                        theme={{
                            theme: 'dark'
                        }}
                    />
                </Worker>
            </DialogContent>
        </Dialog>
    )
}