import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CheckCircle from '@mui/icons-material/CheckCircle';

import DialogAuthMyLibrary from '../DialogAuthMyLibrary';
import DialogPdfReader from '../DialogPdfReader';

import useDataContext from 'context/data.hook';

import { ICardResource } from './types';

import './styles.scss';

export default function CardResource({
    placeCard = 'aprendizagem',
    idResource,
    type,
    description,
    link,
}: ICardResource) {
    const { logs, onHandleUpdateLogs } = useDataContext();

    const getCookie = useCookies(['a_mb'])[0];

    const [urlImageResource, setUrlImageResource] = useState('');

    const [openAuthMyLibrary, setOpenAuthMyLibrary] = useState(false);
    const [openPdfReader, setOpenPdfReader] = useState(false);

    const accessDoc = () => {
        if (link.includes('drive.google') || link.includes('minhabiblioteca')) {
            if (link.includes('drive.google') || (getCookie?.a_mb && link.includes('minhabiblioteca'))) {
                window.open(link, '_blank');
            } else {
                setOpenAuthMyLibrary(true);
            }
        } else if (link.includes('s3.amazonaws')) {
            setOpenPdfReader(true);
        } else {
            window.open(link, '_blank');
        }
        
        onHandleUpdateLogs({
            typeLog: placeCard === 'aprendizagem' ? 'RecursosDeAprendizagem' : 'AprofundandoNoConhecimento',
            resource: idResource
        });
    }

    useEffect(() => {
        if (placeCard === 'aprofundando') {
            setUrlImageResource('https://dirin.s3.amazonaws.com/ecossistema_v5/assets/aprofundandoconhecimento.png');
        } else if (type === 'Livro') {
            setUrlImageResource('https://dirin.s3.amazonaws.com/ecossistema_v5/assets/livrodigital.png');
        } else if (type === 'Documento Digital') {
            setUrlImageResource('https://dirin.s3.amazonaws.com/ecossistema_v5/assets/documentodigital.png');
        } else if (type === 'Conteúdo Web') {
            setUrlImageResource('https://dirin.s3.amazonaws.com/ecossistema_v5/assets/documentoweb.png');
        } else if (type === 'Video') {
            setUrlImageResource('https://dirin.s3.amazonaws.com/ecossistema_v5/assets/aprofundandoconhecimento.png');
        }
    }, [placeCard, type])

    return (
        <Paper elevation={3} className="card_resource">
            <Box className="header_card_resource">
                <Avatar src={urlImageResource} />
                <Typography variant="h6">{type === 'Livro' ? 'Livro Digital' : type}</Typography>
            </Box>

            <Box className="content_card_resource">
                <Typography variant="h6">
                    {description}
                </Typography>
            </Box>

            <Box className="actions_card_resource">
                <Button
                    onClick={accessDoc}
                >Acessar</Button>

                {
                    (placeCard === 'aprendizagem' &&
                    logs?.log_info['RecursosDeAprendizagem'] &&
                    logs?.log_info['RecursosDeAprendizagem'].includes(idResource!)) ||
                    (placeCard === 'aprofundando' &&
                    logs?.log_info['AprofundandoNoConhecimento'] &&
                    logs?.log_info['AprofundandoNoConhecimento'].includes(idResource!)) ? (
                        <CheckCircle className="check"/>
                    ) : <CheckCircleOutline />
                }

            </Box>

            <DialogAuthMyLibrary
                open={openAuthMyLibrary}
                linkAccess={link}
                setOpen={setOpenAuthMyLibrary}
            />

            <DialogPdfReader
                open={openPdfReader}
                link={link}
                setOpen={setOpenPdfReader}
            />
        </Paper>
    )
}