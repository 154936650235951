import { logs_api } from "services";
import { useQuery, useMutation } from "react-query";

import { ILogsProps } from './types/useLogs.types';

export function useGetLogs(ra: string, idUnity: undefined | number) {
    return useQuery(['logs', ra, idUnity], () => {
        return getLogsByRaAndIdSubjectAndIdIUnity(ra, idUnity);
    }, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        refetchInterval: 1000 * 60 * 15
    })
}

export function usePostLogs(){
    return useMutation(
        async (values: ILogsProps) => {
            return postLogs(values);
        }
    );
};

export async function getLogsByRaAndIdSubjectAndIdIUnity(ra: string, idUnity: undefined | number) {
    return await logs_api.get(`logs/ra/${ra}/unidade/${idUnity}`);
}

export async function postLogs(props: ILogsProps) {
    return await logs_api.post('logs', {...props});
}