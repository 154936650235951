import Box from '@mui/material/Box';

import WbIncandescentOutlined from '@mui/icons-material/WbIncandescentOutlined';

import SlideResources from 'pages/LearningResources/SlideResources';
import TitlePage from "pages/components/TitlePage";

import useDataContext from "context/data.hook";

import './styles.scss';
import NothingResource from 'pages/components/NothingResource';

export default function DeepeningKnowledge() {
    const { depeeningKnowledge } = useDataContext();

    return (
        <Box>
            <TitlePage icon={<WbIncandescentOutlined />} title="Aprofundando o Conhecimento" />

            {
                Array.isArray(depeeningKnowledge) ? (
                    <SlideResources resources={depeeningKnowledge} placeCard="aprofundando"/>
                ) : (
                    <NothingResource
                        title="Não foi encontrado material de aprofundamento :("
                        subtitle="Mas logo poderá ser adicionado ;)"
                    />
                )
            }
        </Box>
    )
}