import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { TitlePageProps } from './types';

import './styles.scss';

export default function TitlePage({ icon, title }: TitlePageProps) {
    return (
        <Box className="area_title_page">
            {icon}
            <Typography variant="subtitle1">{title}</Typography>
        </Box>
    )
}