import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import CloudUploadOutlined from '@mui/icons-material/CloudUploadOutlined';
import Add from '@mui/icons-material/Add';

import TitlePage from 'pages/components/TitlePage';
import Loading from 'pages/components/Loading';
import ContributionItem from './ContributionItem';
import DialogNewContribution from './DialogNewContribution';
import DialogComments from './DialogComments';

import { useGetContributeDocument } from 'hooksQuerys/useUnitys';

import useDataContext from 'context/data.hook';

import './styles.scss';
import NothingResource from 'pages/components/NothingResource';

export default function ContributeDocument() {
    const [openNewContribution, setOpenNewContribution] = useState(false);

    const [openComments, setOpenComments] = useState(false);
    const [idContributeSelected, setIdContributeSelected] = useState<number | undefined>(undefined);

    const [valueFilterByRate, setValueFilterByRate] = useState<'Todas' | '1' | '2' | '3' | '4' | '5'>('Todas');
    const [currentPage, setCurrentPage] = useState(1);

    const { unitySelected, subject } = useDataContext();
    const { data, isLoading, refetch, error } = useGetContributeDocument(unitySelected?.id, currentPage, valueFilterByRate);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [currentPage]);

    const handleChangeFilterByRate = (value: 'Todas' | '1' | '2' | '3' | '4' | '5') => {
        setCurrentPage(1);
        setValueFilterByRate(value);

        if (currentPage === 1) {
            setTimeout(() => {
                refetch();
            }, 100);
        }
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    return (
        <Box>
            <TitlePage icon={<CloudUploadOutlined />} title="Contribua com um documento" />

            <Box className="area_contributes">
                <Box className="box_actions_new_and_filter">
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Add />}
                        onClick={() => setOpenNewContribution(true)}
                    >
                        Contribuir
                    </Button>

                    <FormControl variant="filled">
                        <InputLabel>Filtrar por avaliações:</InputLabel>

                        <Select
                            label="Filtrar por avaliações:"
                            value={valueFilterByRate}
                            onChange={(e) => 
                                handleChangeFilterByRate(e.target.value as 'Todas' | '1' | '2' | '3' | '4' | '5')
                            }
                        >
                            <MenuItem value="Todas">Todas</MenuItem>
                            <MenuItem value="1">0 - 1 Estrelinhas</MenuItem>
                            <MenuItem value="2">1 - 2 Estrelinhas</MenuItem>
                            <MenuItem value="3">2 - 3 Estrelinhas</MenuItem>
                            <MenuItem value="4">3 - 4 Estrelinhas</MenuItem>
                            <MenuItem value="5">4 - 5 Estrelinhas</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {
                    Array.isArray(data?.data) && data?.meta && !error ?
                    (
                        <>
                            <Pagination                
                                variant="outlined"
                                color="primary"
                                count={data.meta.last_page}
                                page={currentPage}
                                onChange={(event, page) => {
                                    event.preventDefault();
                                    setCurrentPage(page);
                                }}
                                
                            />
                            
                            {
                                data?.data?.map((contribute: any, index: number) => (
                                    <ContributionItem
                                        key={index}
                                        idContribute={contribute.id}
                                        description={contribute.descricao}
                                        numComments={contribute.meta.comentarios_count}
                                        ra={contribute.ra_aluno}
                                        rate={Number(contribute.avaliacao)}
                                        title={contribute.titulo}
                                        link={contribute.link}
                                        subject={subject.nome}
                                        setOpenComments={setOpenComments}
                                        setIdContribute={setIdContributeSelected}
                                    />
                                ))
                            }

                            <Pagination                
                                variant="outlined"
                                color="primary"
                                count={data.meta.last_page}
                                page={currentPage}
                                onChange={(event, page) => {
                                    setCurrentPage(page);
                                }}
                                
                            />
                        </>
                    ) : (
                        <NothingResource
                            title="Não foi realizado nenhuma contribuição."
                            subtitle="Seja o primeiro a contribuir com seus colegas"
                        />
                    )
                }
            </Box>

            <DialogNewContribution
                open={openNewContribution}
                setOpen={setOpenNewContribution}
            />

            <DialogComments
                open={openComments}
                idContribute={idContributeSelected}
                setOpen={setOpenComments}
            />
        </Box>
    )
}