import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { TabPanelProps } from './types';

import './styles.scss';

export default function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ padding: '1.2rem 0' }}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography className="format_text_guide">{children}</Typography>
          </Box>
        )}
      </div>
    );
}