import { FormEvent, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';


import Toastify from 'components/Toastify';

import { DialogNewContributionProps } from './types';

import useDataContext from 'context/data.hook';
import { RegisterContribute } from 'hooksQuerys/useUnitys';

import './styles.scss';

export default function DialogNewContribution({ open, setOpen }: DialogNewContributionProps) {
    const { theme, unitySelected } = useDataContext();
    const { mutate, isLoading } = RegisterContribute();

    const [dataForm, setDataForm] = useState({
        titulo: '',
        descricao: '',
        link: ''
    });

    const onHandleValuesData = (position: 'titulo' | 'descricao' | 'link', value: string) => {
        setDataForm(oldValue => ({
            ...oldValue,
            [position]: value
        }))
    }

    const onClosed = () => {
        setOpen(false);
        setDataForm({
            titulo: '',
            descricao: '',
            link: ''
        })
    }

    const onValidate = (e: FormEvent) => {
        e.preventDefault();

        if (isLoading) {
            return;
        }

        if (!dataForm.titulo) {
            Toastify({ message: 'Campo de título obrigatório.', type: 'warn'});
            return;
        }

        if (dataForm.titulo.length > 150) {
            Toastify({ message: 'O título deve ter no máximo 150 caracteres.', type: 'warn'});
            return;
        }

        if (!dataForm.link) {
            Toastify({ message: 'Campo de link obrigatório.', type: 'warn'});
            return;
        }

        if (dataForm.link.length > 2000) {
            Toastify({ message: 'O link deve ter no máximo 2000 caracteres.', type: 'warn'});
            return;
        }

        if (!dataForm.link.startsWith('http')) {
            Toastify({ message: 'O link deve ser uma URL válida.', type: 'warn'});
            return;
        }

        if (!dataForm.descricao) {
            Toastify({ message: 'Campo de descrição obrigatório.', type: 'warn'});
            return;
        }

        if (dataForm.descricao.length > 250) {
            Toastify({ message: 'A descrição deve ter no máximo 250 caracteres.', type: 'warn'});
            return;
        }

        onSave();
    }

    const onSave = () => {
        mutate({ ...dataForm, idUnity: unitySelected?.id});
        onClosed();
    }

    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle className={`dialog_title_new_contribution ${theme}`}>
                Contribuir com um documento
            </DialogTitle>

            <form onSubmit={onValidate}>
                <DialogContent dividers>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Título do documento:"
                                placeholder="Digite o título do documento aqui..."
                                required
                                value={dataForm.titulo}
                                onChange={(e) => { onHandleValuesData('titulo', e.target.value) }}
                                error={dataForm.titulo.length === 150}
                                inputProps={{ "maxLength": 150 }}
                            />
                            <FormHelperText error={dataForm.titulo.length === 150}>
                                {dataForm.titulo.length} de no máximo 150 caracteres
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Link do documento:"
                                placeholder="Digite o link do documento aqui..."
                                required
                                value={dataForm.link}
                                onChange={(e) => onHandleValuesData('link', e.target.value)}
                                error={dataForm.link.length === 2000 || (!!dataForm.link && !dataForm.link.startsWith('http'))}
                                inputProps={{ "maxLength": 2000 }}
                            />
                            <FormHelperText error={dataForm.link.length === 2000 || (!!dataForm.link && !dataForm.link.startsWith('http'))}>
                                {dataForm.link.length} de no máximo 2000 caracteres e Precisa ser LINK
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Descrição do documento:"
                                placeholder="Descreva para que possam ver do que este documento se trata..."
                                required
                                multiline
                                minRows={5}
                                maxRows={5}
                                value={dataForm.descricao}
                                onChange={(e) => onHandleValuesData('descricao', e.target.value)}
                                error={dataForm.descricao.length === 250}
                                inputProps={{ "maxLength": 250 }}
                            />
                            <FormHelperText error={dataForm.descricao.length === 250}>
                                {dataForm.descricao.length} de no máximo 250 caracteres
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="success"
                        type="submit"
                        disabled={isLoading}
                    >
                        Enviar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}