import { useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataContext } from 'context/data';
import { INIT_VALUES_STEPS, INIT_VALUES_UNITYS } from 'context/data.init.values';
import {
    TScriptPratical,
    TSubject,
    TUnitys,
    TVideos,
    TDepeeningKnowledge,
    TOnHandleUpdateLogsProps,
    TLearningResources
} from 'context/data.types';
import { ILogsProps } from 'hooksQuerys/types/useLogs.types';

/* Querys */
import { useGetSubject } from 'hooksQuerys/useSubjects';
import { useGetUnitys, useGetPraticalClassScriptUnity, useGetDeepeningKnowledge, useGetLearningResources } from 'hooksQuerys/useUnitys';
import { useGetPraticVideos, useGetVideos } from 'hooksQuerys/useVideos';
import { useGetLogs, usePostLogs } from 'hooksQuerys/useLogs';


export default function DataProvider({ children }: { children: ReactNode }) {
    const navigate = useNavigate();
    const [access, setAccess] = useState<{
        idSubject: string,
        ra: string
    } | undefined>();
    const [theme, setTheme] = useState<'uninta' | 'fal'>('uninta');
    const [logs, setLogs] = useState<ILogsProps | undefined>(undefined);
    const [disciplineProgress, setDisciplineProgress] = useState(0);
    
    /**
     * States para armazenar os steps de navegação e a localização atual
     */
    const [steps, setSteps] = useState(INIT_VALUES_STEPS);
    const [activedStep, setActivedStep] = useState({
        path: 0,
        subpath: 0
    });

    /**
     * States que buscam e armazenam os dados para compartilhar entre os componentes
     */
    const [unitySelected, setUnitySelected] = useState<TUnitys>(INIT_VALUES_UNITYS);

    const subject = useGetSubject(access?.idSubject ?? '');
    const unitys = useGetUnitys(access?.idSubject ?? '');
    const videos = useGetVideos(unitySelected?.id);
    const learningResources = useGetLearningResources(String(unitySelected?.id));
    const videosPratic = useGetPraticVideos(unitySelected?.id);
    const scriptPratical = useGetPraticalClassScriptUnity(unitySelected?.id);
    const depeeningKnowledge = useGetDeepeningKnowledge(unitySelected?.id);
    const dataLogs = useGetLogs(access?.ra ?? '', unitySelected?.id);
    const { mutate: updateLogsInSecondPlan } = usePostLogs();

    const handleActivedStepInSession = (path: number, subpath: number) => {
        sessionStorage.setItem('@md_AS', window.btoa(JSON.stringify({ path, subpath })));
    }

    /** 
     * Verifica se a unidade atual é a última para calcular o step de envio do
     * formulário de avaliação da disciplina
     */
    const onValidateStepDisciplineEvaluation = ({ unity }: Omit<TOnHandleUpdateLogsProps, 'typeLog'>) => {
        if (unitys?.data.indexOf(unity) === (unitys.data?.length - 1)) {
            return false;
        }

        return true;
    }

    /** CALCULA O VALOR DO PERCENTUAL DE CADA STEP PERCORRIDO PELO USUÁRIO  */
    const calcPercentProgressBySteps = ({ typeLog, resource = 0, unity }: TOnHandleUpdateLogsProps) => {
        const percentByUnity = 100 / 4;
        const numStepsFixed = 9;

        let guardPercentByStep =
            percentByUnity /
            (numStepsFixed +
            (Array.isArray(videos.data) ? videos.data.length : 0) +
            (!!scriptPratical.data ? 1 : 0) +
            (Array.isArray(videosPratic.data) ? videosPratic.data.length : 0));

        if (
            typeLog === 'RecursosDeAprendizagem' && 
            ((Array.isArray(learningResources.data) ? learningResources.data : 0) > logs?.log_info[typeLog]?.length!)
        ) {
            return 0;
        }

        if (
            typeLog === 'AprofundandoNoConhecimento' && 
            ((Array.isArray(depeeningKnowledge.data) ? depeeningKnowledge.data : 0) > logs?.log_info[typeLog]?.length!)
        ) {
            return 0;
        }

        return (
            guardPercentByStep +
            (resource ? resource : (
                guardPercentByStep *
                (onValidateStepDisciplineEvaluation({ unity }) ? 2 : 1)
                )
            )).toFixed(2);
    }

    /** VERIFICA SE O STEP CLICADO JÁ FOI ADICIONADO AOS LOGS */
    const onValidateUpdateLogs = ({ typeLog, resource }: TOnHandleUpdateLogsProps) => {
        if (
            typeLog !== 'RecursosDeAprendizagem' &&
            typeLog !== 'AprofundandoNoConhecimento' &&
            typeLog !== 'Videoaulas' &&
            typeLog !== 'VídeosParaAulaPrática'
        ) {
            if (logs?.log_info[typeLog]) {
                return false;
            }
        }

        if (
            (typeLog === 'RecursosDeAprendizagem' &&
            logs?.log_info['RecursosDeAprendizagem']?.includes(resource!)) ||
            (typeLog === 'AprofundandoNoConhecimento' &&
            logs?.log_info['AprofundandoNoConhecimento']?.includes(resource!)) ||
            (typeLog === 'Videoaulas' &&
            logs?.log_info['Videoaulas']?.includes(resource!)) ||
            (typeLog === 'VídeosParaAulaPrática' &&
            logs?.log_info['VídeosParaAulaPrática']?.includes(resource!))
        ) {
            return false;
        }

        return true;
    }

    /** ATUALIZA O ESTADO DE LOGS */
    const onHandleUpdateLogs = ({
        typeLog,
        resource,
        unity
    }: TOnHandleUpdateLogsProps) => {
        if ( onValidateUpdateLogs({ typeLog, resource }) ) {
            let validateDisciplineEvaluation: {} = { AvaliacaoDisciplina: true };

            if (!onValidateStepDisciplineEvaluation({ unity })) {
                validateDisciplineEvaluation = {};
            }

            setLogs(oldValues => {
                const calc = calcPercentProgressBySteps({ typeLog, resource: oldValues?.log_info.percent_progress, unity });

                return ({
                    ...oldValues!,
                    log_info: {
                        ...oldValues?.log_info,
                        ...validateDisciplineEvaluation,
                        percent_progress: Number(calc),
                        ComoEstudar: true,
                        [typeLog as string]: resource ? oldValues?.log_info[typeLog!] ? [...oldValues?.log_info[typeLog!] as number[], resource]: [resource] : true
                    },
                })
            });

            setDisciplineProgress(oldValues => Number(calcPercentProgressBySteps({ typeLog, resource: oldValues, unity })));
        }
    }

    /*
     * Função para ser executada ao clicar no botão "Próxima Etapa"
     * e o usuário ser direcionado para a etapa seguinte
     */
    const nextStep = () => {
        let valuesStepsFormated = JSON.stringify(steps);
        valuesStepsFormated = JSON.parse(valuesStepsFormated);

        const stepActual: any = activedStep.path === -1 ? {
            step: 'AvaliaçaoDaDisciplina',
        } : valuesStepsFormated[activedStep.path];

        let stepNext: any = {};

        if (stepActual.step === 'ContribuaComUmDocumento') {
            if (Array.isArray(depeeningKnowledge)) {
                stepNext = valuesStepsFormated[activedStep.path + 1];
            } else {
                stepNext = valuesStepsFormated[activedStep.path + 2];
            }
        } else {
            stepNext = valuesStepsFormated[activedStep.path + 1];
        }

        if (stepNext?.step === 'Videoaulas' || stepNext?.step === 'VídeosParaAulaPrática') {
            if (stepNext?.videos.length > 0) {
                navigate(`${stepNext.path}/${stepNext.videos[0]}`);
                setActivedStep(oldValues => ({ ...oldValues, path: oldValues.path + 1, subpath: 0 }));
                handleActivedStepInSession(activedStep.path + 1, 0);
                onHandleUpdateLogs({ typeLog: stepNext.step, resource: stepNext.videos[0] });
                return;
            } else if (stepNext?.step === 'Videoaulas') {
                const jump: any = valuesStepsFormated[activedStep.path + 2];
                navigate(`${jump?.path}`);
                setActivedStep(oldValues => ({ ...oldValues, path: oldValues.path + 2 }));
                handleActivedStepInSession(activedStep.path + 1, 0);
                onHandleUpdateLogs({ typeLog: jump.step });
                return;
            } else if (stepNext?.step === 'VídeosParaAulaPrática') {
                return;
            }
        }

        if (stepActual?.step === 'Videoaulas' || stepActual?.step === 'VídeosParaAulaPrática') {
            if (!(stepActual?.videos.length <= activedStep.subpath + 1)) {
                navigate(`${stepActual?.path}/${stepActual?.videos[activedStep.subpath + 1]}`);
                setActivedStep(oldValues => ({ ...oldValues, subpath: activedStep.subpath + 1 }));
                handleActivedStepInSession(activedStep.path, activedStep.subpath + 1);
                onHandleUpdateLogs({ typeLog: stepActual.step, resource: stepActual?.videos[activedStep.subpath + 1] });
                return;
            }
        }

        navigate(`${stepNext?.path}`);
        setActivedStep(oldValues => ({ ...oldValues, path: activedStep.path + 1 }));
        handleActivedStepInSession(activedStep.path + 1, 0);

        if (stepNext.step !== 'RecursosDeAprendizagem' && stepNext.step !== 'AprofundandoNoConhecimento') {
            onHandleUpdateLogs({ typeLog: stepNext.step });
        }
    }

    /**
     * Este efeito buscará os dados de sessão que contém o id da disciplina acessada
     */
    useEffect(() => {
        const storageLocal = sessionStorage.getItem('@md_S');

        if (storageLocal) {
            setAccess(JSON.parse(window.atob(storageLocal)));
        }
        // eslint-disable-next-line
    }, [sessionStorage.getItem('@md_S')]);

    /**
     * Este efeito buscará o tema
     */
     useEffect(() => {
        const storageSession: any = sessionStorage.getItem('@md_T');

        if (storageSession) {
            setTheme(window.atob(storageSession) as 'fal' | 'uninta');
        }
        // eslint-disable-next-line
    }, [sessionStorage.getItem('@md_T')]);

    /**
     * Este efeito seta o valor da primeira unidade
     * do array que o fetch busca, dentro do estado
     * de unidade selecionada.
     * 
     * obs: isso é necessário para buscar os vídeos
     * da primeira unidade inicial.
     */
    useEffect(() => {
        if (!!unitys.data) {
            setUnitySelected(unitys.data[0]);
        }
    }, [unitys.data]);


    /**
     * Busca os vídeos e adiciona no steps
     */
    useEffect(() => {
        const getArrayVideosIds = videos.data?.map((dt: any) => {return dt.id});
        const getArrayVideosPraticsIds = videosPratic.data?.map((dt: any) => {return dt.id});

        setSteps(oldValues => ({
            ...oldValues,
            3: {
                ...oldValues[3],
                videos: Array.isArray(getArrayVideosIds) ? [...getArrayVideosIds] : []
            },
            10: {
                ...oldValues[10],
                videos: Array.isArray(getArrayVideosPraticsIds) ? [...getArrayVideosPraticsIds] : []
            },
        }));
    }, [videos.data, videosPratic.data]);

    /**
     * Ao recarregar a página verifica se
     * existe no storage tem algum step já
     * armazenado e seta no actived step
     */
    useEffect(() => {
        let activedStepStorage: any = sessionStorage.getItem('@md_AS');

        if (activedStepStorage) {
            activedStepStorage = JSON.parse(window.atob(activedStepStorage));

            setActivedStep({
                path: activedStepStorage?.path ?? 0,
                subpath: activedStepStorage?.subpath ?? 0
            });
        }
    }, []);

    /**
     * Ao recarregar a página busca os logs
     * de progresso do usuário
     */
    useEffect(() => {
        if ((!dataLogs.data && !logs) || (unitySelected?.id !== logs?.id_unidade)) {
            setLogs({
                ra: access?.ra ?? '',
                id_disciplina: access?.idSubject ?? '',
                id_unidade: unitySelected.id,
                log_info: { percent_progress: 0 }
            });
        }

        if (dataLogs.data?.data) {
            setLogs(oldValues => ({
                ...oldValues!,
                log_info: dataLogs.data?.data.log_info!
            }));
        }
        // eslint-disable-next-line
    }, [dataLogs.data]);

    /**
     * Atualiza os logs sempre que o estado dos
     * logs atualizar
     */
    useEffect(() => {
        if (!!logs && logs.log_info.percent_progress > 0 && Object.entries(logs.log_info).length > 1) {
            updateLogsInSecondPlan({...logs!});
        }
        // eslint-disable-next-line
    }, [logs]);

    return (
        <DataContext.Provider
            value={{
                theme,
                ra: access?.ra ?? '',
                subject: subject.data as TSubject,
                unitys: unitys.data as TUnitys[],
                learningResources: learningResources.data as TLearningResources[],
                videos: videos.data as TVideos[],
                pratic_videos: videosPratic.data as TVideos[],
                script_pratical: scriptPratical.data as TScriptPratical | any,
                depeeningKnowledge: depeeningKnowledge.data as TDepeeningKnowledge,
                unitySelected,
                setUnitySelected,
                nextStep,
                setActivedStep,
                logs,
                disciplineProgress,
                onHandleUpdateLogs
            }}
        >
            {children}
        </DataContext.Provider>
    )
}