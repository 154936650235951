import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Chart } from "react-google-charts";

import Loading from "pages/components/Loading";
import NothingResource from 'pages/components/NothingResource';

import useDataContext from "context/data.hook";
import { useGetRatesUnity } from "hooksQuerys/useUnitys";

import './styles.scss';

export default function ChartEmojis() {
    const { unitySelected } = useDataContext();
    const { data, isLoading } = useGetRatesUnity(unitySelected?.id);

    const [totalRates, setTotalRates ] = useState(0);

    const [rates, setRates] = useState([
        ["Avaliações", "Avaliações da unidade"],
        ["Ótima", 0],
        ["Boa", 0],
        ["Regular", 0],
        ["Ruim", 0],
        ["Péssima", 0],
    ]);
      
    const options = {
        pieHole: 0.4,
        legend: "none",
        pieSliceText: "none",
        slices: {
            0: { color: '#129f05' },
            1: { color: '#0b32a7' },
            2: { color: '#550ba7' },
            3: { color: '#fd8f29' },
            4: { color: '#b50000' },
        },
    };

    useEffect(() => {
        const getTotalEvaluates = data?.resumAvaliacoes?.otima + data?.resumAvaliacoes?.boa + data?.resumAvaliacoes?.regular + data?.resumAvaliacoes?.ruim + data?.resumAvaliacoes?.pessima;

        setTotalRates(getTotalEvaluates);
        setRates([
            ["Avaliações", "Avaliações da unidade"],
            ["Ótima", data?.resumAvaliacoes?.otima ?? 0],
            ["Boa", data?.resumAvaliacoes?.boa ?? 0],
            ["Regular", data?.resumAvaliacoes?.regular ?? 0],
            ["Ruim", data?.resumAvaliacoes?.ruim ?? 0],
            ["Péssima", data?.resumAvaliacoes?.pessima ?? 0],
        ]);
    }, [data]);

    return (
        <Box className="area_chart_emojis">
            <Typography variant="h6" className={totalRates ? "have_evaluates" : ""}>Avaliações realizadas</Typography>
            
            {
                isLoading ? (
                    <Loading />
                ) : (
                    <>
                        {
                        totalRates ? (
                                <Chart
                                    chartType="PieChart"
                                    data={rates}
                                    options={options}
                                    width="100%"
                                    height="30rem"
                                />
                            ) : (
                                <NothingResource
                                    title="Não há avaliações."
                                    subtitle="Seja o primeiro a avaliar. ;)"
                                />
                            )
                        }

                        <Box className="header_labels">
                            {
                                Array.isArray(rates) && rates.map((dt: any, position: number) => {
                                    let colors: any = JSON.stringify(options.slices);
                                    colors = JSON.parse(colors);

                                return position !== 0 && (
                                        <Box className="info_label" key={position}>
                                            <Box className="area_info_color_label" style={{ background: colors?.[position - 1]?.color }}/>
                                            <Typography className="label">{dt[0]}</Typography>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </>
                )
            }
        </Box>
    )
}