import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import StarBorder from '@mui/icons-material/StarBorder';

import NothingResource from 'pages/components/NothingResource';
import BoxQuestion from './BoxQuestion';

import useDataContext from 'context/data.hook';
import { useGetQuizEvaluation, usePostQuizDisciplineEvaluation } from 'hooksQuerys/useSubjects';

import './styles.scss';
import { FormEvent, useState } from 'react';
import Loading from 'pages/components/Loading';


export default function DisciplineEvaluationForm() {
    const { subject, onHandleUpdateLogs } = useDataContext();
    const { mutate, isLoading: isLoadingSendQuiz } = usePostQuizDisciplineEvaluation();
    const { data, isLoading, refetch } = useGetQuizEvaluation(subject?.id);

    const [responses, setResponses] = useState({
        respostas: {}
    });

    const handleChangeValuesResponses = (position: string, value: string) => {
        setResponses(oldValues => ({
            respostas: {
                ...oldValues.respostas,
                [position]: value
            }
        }));
    }

    const onSave = async (e: FormEvent) => {
        e.preventDefault();

       mutate({
            respostas: { ...responses.respostas },
            idSubject: subject.id
        });

        setResponses({ respostas: {} });
        setTimeout(() => {
            refetch();
            onHandleUpdateLogs({ typeLog: 'AvaliacaoDisciplina' });
        }, 100);
    }

    if (isLoadingSendQuiz) {
        return (
            <Loading
                title="Sua avaliação está sendo enviada..."
            />
        )
    }
    
    return (
        <Box className="box_quiz">
            {
                isLoading ? (
                    <Loading />
                ) : (
                    <>
                        { 
                            data.avaliou ?
                                <Box className="box_evaluated">
                                    <Typography>
                                        <StarBorder />
                                        Você já avaliou esta disciplina
                                        <StarBorder />
                                    </Typography>
                                    <Typography>
                                        Obrigado pela sua colaboração, sua participação é essencial para a nossa melhoria e garantia de qualidade.
                                    </Typography>
                                </Box>
                            : 
                            <>
                                <Typography className="title_discipline_evaluation">Avaliar Disciplina</Typography>
                                <Typography className="subtitle_discipline_evaluation">
                                    Avaliando esta disciplina, você nos ajuda a melhorar
                                    cada vez mais sua experiência e de seus colegas durante
                                    a utilização do desse material didático.
                                </Typography>

                                {
                                    Array.isArray(data?.perguntas) && data?.perguntas.length > 0 ? 
                                        <form onSubmit={onSave}>
                                            {
                                                data?.perguntas.map((qt: any, index: number) => (
                                                    <BoxQuestion
                                                        key={index}
                                                        idItem={qt.id}
                                                        numItem={index + 1}
                                                        statement={qt.enunciado}
                                                        lengends={qt.legenda}
                                                        changeResponses={handleChangeValuesResponses}
                                                    />
                                                ))
                                            }

                                            <Button
                                                variant="contained"
                                                color="success"
                                                type="submit"
                                                disabled={isLoadingSendQuiz}
                                                fullWidth
                                                size="large"
                                            >
                                                Enviar
                                            </Button>
                                        </form>
                                    :
                                        <NothingResource
                                            title="Nenhuma pergunta cadastrada"
                                            subtitle="Não foram encontradas perguntas para esta disciplina"
                                        />
                                }
                            </>
                        }
                    </>
                )
            }
        </Box>
    )
}