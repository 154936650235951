import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

import { SingIn } from 'hooksQuerys/useLogin';
import { useGetSubject } from 'hooksQuerys/useSubjects';

import Lottie from 'react-lottie';
import * as animationData from 'assets/material-log.json';

import './styles.scss';

type TParamsProps = {
    idSubject: string;
    tokenTutor: string;
    acess: string;
}

export default function Login({ typeTheme = 'uninta' }: { typeTheme?: 'uninta' | 'fal' }) {
    const navigate = useNavigate();
    const onLogin = SingIn();
    const [isPassword, setIsPassword] = useState(true);

    const params = useParams<TParamsProps>();
    
    const subject = useGetSubject(params.idSubject ?? '00000');

    const [dataForm, setDataForm] = useState({
        ra: '',
        password: ''
    });

    const onHandleValuesDataForm = (position: string, value: string) => {
        setDataForm(oldValues => ({
            ...oldValues,
            [position]: value
        }))
    }

    const accessTutor = () => {
        const values = {
            AccessToken: window.atob('true'),
            ra: '',
            idSubject: params.idSubject
        }

        sessionStorage.setItem('@md_S', window.btoa(JSON.stringify(values)));
        navigate('/como-estudar');
    }

    useEffect(() => {
        if (
            params.tokenTutor &&
            window.atob(params.tokenTutor) === 'acessodotutorsecreto'
        ) {
            accessTutor();
        }

        /**
         * Gera o acesso do tutor
         * função: console.log(window.btoa('acessodotutorsecreto'));
         **/
        // eslint-disable-next-line
    }, [params.tokenTutor]);

    useEffect(() => {
        if (typeTheme) {
            const teste = sessionStorage.getItem('@md_T');

            if (!teste) {
                sessionStorage.setItem('@md_T', window.btoa(typeTheme));
            }
        }
    }, [typeTheme]);

    useEffect(() => {
        if (params.acess) {
            const acessEcossistema = JSON.parse(window.atob(params.acess));

            sessionStorage.setItem('@md_S', window.btoa(JSON.stringify({
                AccessToken: acessEcossistema.AccessToken,
                idSubject: acessEcossistema.idSubject,
                ra: acessEcossistema.ra,
            })));

            if (acessEcossistema.theme) {
                sessionStorage.setItem('@md_T', window.btoa(acessEcossistema.theme));
            }
            
            if (subject.isLoading) {
                window.location.href = '/como-estudar';
            }
        }
        //eslint-disable-next-line
    }, [params.acess])

    const forgotPassword = () => {
        const windowLink = `https://academico.aiamis.com.br/Corpore.Net/SharedServices/LibPages/RecoverPassConfirmation.aspx?UserCaption=5LK%5c9F%5c3D%5c023%5c5B&ConfirmationCaption=%5c7B%5cFAbP%5c06%5c11Q%5c7C&RecoverContainerClassName=ASP.login_aspx,%20App_Web_p2weljrl,%20Version=0.0.0.0,%20Culture=neutral,%20PublicKeyToken=null&RecoverInitializeMethodName=GetRecoverPassServer&ServiceAlias=CorporeRM`;
        const windowType = "popup";
        const windowMeasurements = "left=100,top=100,width=320,height=320";

         window.open(windowLink, windowType, windowMeasurements);
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        onLogin.mutate({
            ...dataForm,
            idSubject: params.idSubject ?? '',
        });
    }

    if (onLogin.isSuccess) {
        window.location.href = '/como-estudar';
    }

    if (subject.isLoading) {
        return <Box>
            <CircularProgress />
        </Box>;
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Container maxWidth="sm">
            <Box className="login">
                <Lottie
                    options={defaultOptions}
                    height={280}
                    width={280}
                />

                {
                    params.idSubject && !subject.isError ? (
                        <>
                            <Box className="title_subject_login">
                                <Typography variant="h5">Utilize o seu RA e senha para acessar a disciplina de:</Typography>
                                <Typography variant="h4">{subject.data?.nome}</Typography>
                            </Box>

                            <Box className="credentials">
                                <form onSubmit={onSubmit} className="credentials">
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="RA:"
                                        required
                                        value={dataForm.ra}
                                        onChange={(e) => onHandleValuesDataForm('ra', e.target.value)}
                                    />

                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password">Senha:</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            type={isPassword ? 'password' : 'text'}
                                            value={dataForm.password}
                                            onChange={(e) => onHandleValuesDataForm('password', e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setIsPassword(!isPassword)}
                                                        edge="end"
                                                    >
                                                        {isPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Senha:"
                                        />
                                    </FormControl>

                                    <Button
                                        variant="outlined"
                                        type="submit"
                                        disabled={onLogin.isLoading}
                                    >
                                        {onLogin.isLoading ? <CircularProgress style={{ width: 20, height: 20 }} /> : 'Entrar'}
                                    </Button>

                                    <Button
                                        variant="text"
                                        color="secondary"
                                        name="remember_password"
                                        onClick={forgotPassword}
                                    >
                                        Esqueceu sua senha?
                                    </Button>
                                </form>
                            </Box>
                        </>
                    ) : (
                        <Typography variant="h5">
                            Verifique o link que você navegou, caso tenha modificado a url, entre no Ecossistema de Aprendizagem e acesse
                            o link correto ou caso esse erro persistir informe ao setor responsável.
                        </Typography>
                    )
                }

                <Typography className="baseboard">Material Didátio - Copyright © - AIAMIS - {new Date().getFullYear()}</Typography>
            </Box>
        </Container>
    )
}