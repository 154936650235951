import parser from 'html-react-parser';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ArticleOutlined from "@mui/icons-material/ArticleOutlined";

import TitlePage from "pages/components/TitlePage";

import useDataContext from "context/data.hook";

import './styles.scss';

export default function UnitSummary() {
    const { unitySelected } = useDataContext();

    return (
        <Box className="area_unit_summary">
            <TitlePage icon={<ArticleOutlined />} title="Resumo da Unidade de Estudo" />

            <Typography variant="h2">{unitySelected?.titulo_unidade}</Typography>

            <Box className="area_gols">
                <Typography variant="h5">Objetivos:</Typography>

                <Typography variant="h6">
                    {unitySelected?.objetivos_unidade ? parser(unitySelected?.objetivos_unidade) : ''}
                </Typography>
            </Box>

            <Box className="area_summary">
                <Typography variant="h5">Resumo da unidade:</Typography>

                <Typography variant="h6">
                    {unitySelected?.resumo ? parser(unitySelected?.resumo) : ''}
                </Typography>
            </Box>
        </Box>
    )
}