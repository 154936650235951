import { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import Toastify from 'components/Toastify';

import { RegisterRateContribute } from 'hooksQuerys/useUnitys';

import { RateContributionProps } from './types';

import './styles.scss';

export default function RateContribution({ open, idContribute, setOpen }: RateContributionProps) {
    const { mutate, isLoading,  } = RegisterRateContribute();

    const [rate, setRate] = useState(0);

    const onClosed = () => {
        setOpen(false);
        setRate(0);
    }

    const onValidate = () => {
        if (!idContribute) {
            Toastify({ message: 'Preencha o campo de identificação do comentário.', type: 'warn'});
            return;
        }

        if (!rate) {
            Toastify({ message: 'Preencha a avaliação.', type: 'warn'});
            return;
        }

        onSave();
    }

    const onSave = () => {
        mutate({ idContribute, avaliacao: rate });
        
        setTimeout(() => {
            onClosed();
        }, 1000);
    }

    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle className="dialog_title_rate_contribution">
                <Typography className="text_title">Avaliar Contribuição</Typography>
            </DialogTitle>

            <DialogContent dividers className="dialog_content_rate_contribution">
                <Typography className="text_subtitle">
                    Avalie para outros verem como esta contribuição é útil.
                </Typography>

                <Rating
                    value={rate}
                    onChange={(e) => {
                        const num = (e.target as HTMLInputElement).value;

                        setRate(Number(num));
                    }}
                    disabled={isLoading}
                />

                <Box display="flex" gap={2}>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={onClosed}
                        disabled={isLoading}
                    >Cancelar</Button>

                    <Button
                        variant="outlined"
                        className="btnRate"
                        onClick={onValidate}
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress style={{ height: 20, width: 20 }} /> : 'Avaliar'}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
