import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { NothingResourceProps } from './types';

import './styles.scss';

export default function NothingResource({ title, subtitle }: NothingResourceProps) {
    return (
        <Box className="area_void">
            <Typography className="void">
                {title}
                <small>{subtitle}</small>
            </Typography>
        </Box>
    )
}