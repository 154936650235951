import { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import ThumbUpOutlined from '@mui/icons-material/ThumbUpOutlined';
import Telegram from '@mui/icons-material/Telegram';

import TitlePage from 'pages/components/TitlePage';
import Toastify from 'components/Toastify';
import EmojisEvaluate from './EmojisEvaluate';
import ChartEmojis from './ChartEmojis';

import { RegisterRateUnity } from 'hooksQuerys/useUnitys';
import useDataContext from 'context/data.hook';

import './styles.scss';

export default function EvaluateUnit() {
    const { unitySelected } = useDataContext();
    const { mutate, isLoading } = RegisterRateUnity();

    const [dataForm, setDataForm] = useState({
        rate: '' as 'Ótima' | 'Boa' | 'Regular' | 'Ruim' | 'Péssima' | '',
        feedback: ''
    });

    const onHandleValuesData = (position: 'rate' | 'feedback', value: string) => {
        setDataForm(oldValues => ({
            ...oldValues,
            [position]: value
        }))
    }

    const onSave = () => {
        mutate({ ...dataForm, idUnity: unitySelected?.id });

        setDataForm({
            rate: '',
            feedback: ''
        })
    }

    const onValidate = () => {
        if (!dataForm.rate) {
            Toastify({ message: 'Preencha a identificação da unidade.', type: 'warn' })
            return;
        }
        
        if (!dataForm.rate) {
            Toastify({ message: 'Preencha o campo de avaliação.', type: 'warn' })
            return;
        }

        onSave();
    }

    return (
        <Box>
            <TitlePage icon={<ThumbUpOutlined />} title="Avaliar Unidade"/>

            <Grid container>
                <Grid item xs={12} md={6} className="area_evaluate_unit">
                    <Typography className="info_evaluate">Selecione um dos emojis de acordo com sua avaliação.</Typography>

                    <EmojisEvaluate rate={dataForm.rate} setRate={onHandleValuesData} />

                    <TextField
                        variant="outlined"
                        label="Avaliação:"
                        placeholder="Conte para a gente o que você achou dessa unidade..."
                        multiline
                        minRows={4}
                        maxRows={4}
                        fullWidth
                        value={dataForm.feedback}
                        onChange={(e) => setDataForm(oldValues => ({ ...oldValues, feedback: e.target.value }))}
                        disabled={isLoading}
                        style={{ width: '95%' }}
                    />

                    <Button
                        variant="contained"
                        endIcon={<Telegram /> }
                        onClick={onValidate}
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress style={{ height: 20, width: 20 }} /> : 'Enviar'}
                    </Button>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box className="chart_emojis">
                        <ChartEmojis />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}