import { useState, useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { uninta, fal } from 'theme';

import {
    QueryClientProvider,
    QueryClient,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import DataProvider from '../context/DataProvider';

import { CookiesProvider } from 'react-cookie';

import { ToastContainer } from 'react-toastify';

export default function ProvidersGlobal({ children }: { children: React.ReactElement }) {
    const queryClient = new QueryClient();
    const [theme, setTheme] = useState<'uninta' | 'fal'>('uninta');

    /**
     * Este efeito buscará o tema
     */
     useEffect(() => {
        const storageSession: any = sessionStorage.getItem('@md_T');

        if (storageSession) {
            setTheme(window.atob(storageSession) as 'fal' | 'uninta');
        }
        // eslint-disable-next-line
    }, [sessionStorage.getItem('@md_T')]);

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme === 'fal' ? fal : uninta}>
                <QueryClientProvider client={queryClient}>
                    <DataProvider>
                        <CookiesProvider>
                            <ReactQueryDevtools initialIsOpen={false}/> 
                            {children}

                            <ToastContainer position='top-center' autoClose={2500} />
                        </CookiesProvider>
                    </DataProvider>
                </QueryClientProvider>
            </ThemeProvider>
        </BrowserRouter>
    )
}