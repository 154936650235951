import { Routes as Switch, Route, Navigate } from 'react-router-dom';

import HowToStudy from 'pages/HowToStudy';
import LearningResources from 'pages/LearningResources';
import UnitSummary from 'pages/UnitSummary';
import Videoclass from 'pages/Videoclass';
import StudyGuide from 'pages/StudyGuide';
import SharingIdeas from 'pages/SharingIdeas';
import ContributeDocument from 'pages/ContributeDocument';
import DeepeningKnowledge from 'pages/DeepeningKnowledge';
import EvaluateUnit from 'pages/EvaluateUnit';
import PraticalClassScript from 'pages/PraticalClassScript';

import NotFound from 'pages/NotFound';
import DisciplineEvaluationForm from 'pages/DisciplineEvaluationForm';

export default function Authenticated() {
    return (
        <Switch>
            <Route path="/" element={<Navigate to="/como-estudar" replace />}>
                <Route path="/:idSubject" element={<Navigate to="/resumo-unidade" replace />} />
            </Route>

            <Route path='avaliar-disciplina' element={<DisciplineEvaluationForm />}/>
            <Route path='como-estudar' element={<HowToStudy />} />
            <Route path='resumo-unidade' element={<UnitSummary />}/>
            <Route path='recursos-aprendizagem' element={<LearningResources />}/>
            <Route path='videoaulas/:idVideoclass' element={<Videoclass />}/>
            <Route path='aula-pratica/:idVideoclass' element={<Videoclass />}/>
            <Route path='guia-estudos' element={<StudyGuide />}/>
            <Route path='partilhando-ideias' element={<SharingIdeas />}/>
            <Route path='contribuindo-documento' element={<ContributeDocument />}/>
            <Route path='aprofundando-conhecimento' element={<DeepeningKnowledge />}/>
            <Route path='avaliar-unidade' element={<EvaluateUnit />}/>
            <Route path='roteiro-pratica' element={<PraticalClassScript />}/>
            
            <Route path='*' element={<NotFound />}/>
        </Switch>
    )
}
