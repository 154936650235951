import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ReactPlayer from 'react-player'

import Box from '@mui/material/Box';
import TitlePage from 'pages/components/TitlePage';

import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';

import useDataContext from 'context/data.hook';

import { TVideos } from 'context/data.types';

import './styles.scss';

export default function Videoclass() {
    const { idVideoclass } = useParams();
    const { videos } = useDataContext();

    const [videoSelected, setVideoSelected] = useState<TVideos>();

    useEffect(() => {
        if (!!videos) {
            const getVideoById = videos?.filter(video => video.id === Number(idVideoclass))[0];

            setVideoSelected(getVideoById);
        }
    }, [videos, idVideoclass]);

    return (
        <Box>
            <TitlePage icon={<PlayCircleOutline />} title={videoSelected?.titulo_video ?? ''}/>

            <Box className="area_play">
                <ReactPlayer
                    url={videoSelected?.link_video}
                    className="player"
                    controls
                />
            </Box>
        </Box>
    )
}