import { Suspense, lazy } from 'react';

import Drawer from '@mui/material/Drawer';
import Loading from 'pages/components/Loading';

const ListMenuNavigation = lazy(() => import('components/ListMenuNavigation'));

export default function Sidebar() {
    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{ display: { xs: 'none', md: 'block' } }}
        >
            <Suspense fallback={Loading}>
                <ListMenuNavigation />
            </Suspense>
        </Drawer>
    )
}