import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

import Card from '../CardResource';

import { ISlideResourcesProps } from './types';

export default function SlideResources({ resources, placeCard }: ISlideResourcesProps) {
    const scrollRight = () => {
        const area: HTMLElement | null = document.getElementById('slide');

        if (area) {
            area.scrollLeft += 280;
        }
    }

    const scrollLeft = () => {
        const area: HTMLElement | null = document.getElementById('slide');

        if (area) {
            area.scrollLeft -= 280;
        }
    }

    return (
        <Box className="area_learning_resources">
            <IconButton className="btn_slide_left" onClick={scrollLeft}>
                <ArrowBackIosNew />
            </IconButton>

            <Box className="slide" id="slide">
                {
                    resources?.map((resource) => (
                        <Card
                            key={resource?.id}
                            idResource={resource?.id}
                            placeCard={placeCard}
                            type={resource?.tipo}
                            description={resource?.descricao}
                            link={resource?.link}
                        />
                    ))
                }
            </Box>

            <IconButton className="btn_slide_right" onClick={scrollRight}>
                <ArrowForwardIos />
            </IconButton>
        </Box>
    )
}