import { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import BallotOutlined from '@mui/icons-material/BallotOutlined';
import AttachFile from '@mui/icons-material/AttachFile';

import TitlePage from 'pages/components/TitlePage';
import Toastify from 'components/Toastify';

import useDataContext from 'context/data.hook';

import './styles.scss';

export default function PraticalClassScript() {
    const { script_pratical } = useDataContext();
    const [loadingScriptPratical, setLoadingScriptPratical] = useState(false);

    const getScriptPratical = () => {
        setLoadingScriptPratical(true);

        if (script_pratical.data.guiaPraticoUrl) {
            window.open(script_pratical.data.guiaPraticoUrl, '_blank');
        } else {
            Toastify({ message: 'Esta unidade não possui roteiro pratica.', type: 'warn'});
        }

        setLoadingScriptPratical(false);
    }

    return (
        <Box>
            <TitlePage icon={<BallotOutlined />} title="Roteiro para aula prática"/>

            <Box className="area_pratical_class">
                <Typography variant="h6">
                    Acesse o roteiro para aula prática que será realizada no polo de acordo com a programação definida pelo seu polo local.
                </Typography>

                <Button
                    variant="contained"
                    endIcon={<AttachFile />}
                    style={{ alignSelf: 'flex-end' }}
                    disabled={loadingScriptPratical}
                    startIcon={loadingScriptPratical ? <CircularProgress style={{ width: 20, height: 20 }} /> : <></>}
                    onClick={() => getScriptPratical()}
                >
                    Roteiro para aula prática
                </Button>
            </Box>
        </Box>
    )
}