import axios from 'axios';

/**
 * Identifica o ambiente em que a aplicação está sendo executada
 */
const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

export const ecossistema_api = axios.create({
    baseURL: IS_DEVELOPMENT ?
        process.env.REACT_APP_URL_API_LOCAL_ECOSSISTEMA
        : process.env.REACT_APP_URL_API_ECOSSISTEMA
});

export const material_api = axios.create({
    baseURL: IS_DEVELOPMENT ?
        process.env.REACT_APP_URL_API_LOCAL_MATERIAL
        : process.env.REACT_APP_URL_API_MATERIAL
});

export const logs_api = axios.create({
    baseURL: IS_DEVELOPMENT ?
        process.env.REACT_APP_URL_API_LOCAL_LOGS
        : process.env.REACT_APP_URL_API_LOGS
});

/**
 * Setando o token e o ra_aluno global no header das requisições
 */
material_api.interceptors.request.use(config => {
    let data: any = sessionStorage.getItem('@md_S');

    if (data) {
        data = JSON.parse(window.atob(data));
    }

    if (!!data && config.headers) {
        config.headers['Authorization'] = `Bearer ${data.AccessToken}`;
        config.headers['ra_aluno'] = data.ra;
        config.headers['type_user'] = data.ra ? 'student' : 'tutor';
    }

    return config;
})