import { material_api } from 'services';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
    GetRegisterNotepadProps,
    IRegisterCommentContribute,
    IRegisterContributeProps,
    IRegisterRateContribute,
    IRegisterRateUnity
} from './types/useUnity.types';

import Toastify from 'components/Toastify';

/**
 * Querys Cached
 */
export function useGetUnitys(idSubject: string) {
    return useQuery(
        ['unitys', idSubject],
        () => getUnitysByIdSubject(idSubject),
        {
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            retry: false
        }
    )
}

export function useGetLearningResources(idUnity: string) {
    return useQuery(
        ['learning_resources', idUnity],
        () => getLearningResourcesByIdUnity(idUnity),
        {
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            retry: false
        }
    )
}

export function useGetNotepad(idUnity: number | undefined) {
    return useQuery(
        ['notepad', idUnity],
        () => getNotepadByIdUnity(idUnity),
        {
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            retry: false
        }
    )
}

export function RegisterNotepad() {
    const queryClient = useQueryClient();

    return useMutation(
        async (values: GetRegisterNotepadProps) => await postRegisterNotepad(values),
        {
            onSuccess: () => {
                Toastify({ message: 'Anotações Atualizada.', type: 'success' })
                queryClient.invalidateQueries('notepad');
            }
        }
    )
}

export function useGetContributeDocument(idUnity: number | undefined, paginator = 1, rate: string) {
    return useQuery(
        ['contributes', idUnity],
        () => getContributeDocumentByIdUnity(idUnity, paginator, rate),
        {
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            retry: false
        }
    )
}

export function useShowContributeDocument(idContribute: number | undefined) {
    return useQuery(
        ['contributes_details', idContribute],
        () => showContributeDocumentByIdUnity(idContribute),
        {
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            retry: false
        }
    )
}

export function RegisterContribute() {
    const queryClient = useQueryClient();

    return useMutation(
        async (values: IRegisterContributeProps) => await postRegisterContribute(values),
        {
            onSuccess: () => {
                Toastify({ message: 'Contribuição realizada.', type: 'success' })
                queryClient.invalidateQueries('contributes');
            }
        }
    )
}

export function RegisterRateContribute() {
    const queryClient = useQueryClient();

    return useMutation(
        async (values: IRegisterRateContribute) => await putRegisterRate(values),
        {
            onSuccess: () => {
                Toastify({ message: 'Obrigado por sua avaliação.', type: 'success' })
                queryClient.invalidateQueries('contributes');
            }
        }
    )
}

export function RegisterCommentContribute() {
    return useMutation(
        async (values: IRegisterCommentContribute) => await postRegisterCommentContribute(values)
    )
}

export function useGetDeepeningKnowledge(idUnity: number | undefined) {
    return useQuery(
        ['deepening_knowledge', idUnity],
        () => getDeepeningKnowledgeByIdUnity(idUnity),
        {
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            retry: false
        }
    )
}

export function useGetRatesUnity(idUnity: number | undefined) {
    return useQuery(
        ['rates_unity', idUnity],
        () => getRatesByIdUnity(idUnity),
        {
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            retry: false
        }
    )
}

export function RegisterRateUnity() {
    const queryClient = useQueryClient();

    return useMutation(
        async (values: IRegisterRateUnity) => await postRegisterRateUnity(values),
        {
            onSuccess: () => {
                Toastify({ message: 'Obrigado por sua avaliação', type: 'success' })
                queryClient.invalidateQueries('rates_unity');
            }
        }
    )
}

export function useGetPraticalClassScriptUnity(idUnity: number | undefined) {
    return useQuery(
        ['script_pratical', idUnity],
        () => getPraticalClassScriptByIdUnity(idUnity),
        {
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            retry: false
        }
    )
}

/*
 * Requisições
 */
async function getUnitysByIdSubject(idSubject: string) {
    if (!idSubject) {
        return {
            data: {}
        }
    }

    const { data } = await material_api.get(`disciplina/${idSubject}/unidades`);
    return data;
}

async function getLearningResourcesByIdUnity(idUnity: string) {
    if (!idUnity) {
        return {
            data: {}
        }
    }

    const { data } = await material_api.get(`unidade/${idUnity}/recursos-aprendizagem`);
    return data;
}

async function getNotepadByIdUnity(idUnity: number | undefined) {
    if (!idUnity) {
        return {
            data: {}
        }
    }

    const { data } = await material_api.get(`unidade/${idUnity}/bloco-de-notas`);
    return data;
}

async function postRegisterNotepad({ texto, idUnity, idNotepad, whatAction }: GetRegisterNotepadProps) {
    if (!idUnity) {
        return {
            data: {}
        }
    }

    if (whatAction === 'update' && !idNotepad) {
        return {
            data: ''
        }
    }

    const rota: { url: string; method: 'post' | 'put' } = whatAction === 'create' ? {
        url: `unidade/${idUnity}/bloco-de-notas`,
        method: 'post'
    } : {
        url: `unidade/${idUnity}/bloco-de-notas/${idNotepad}`,
        method: 'put'
    };

    const { data } = await material_api[rota.method](rota.url, {
        texto,
    });

    return data;
}

async function getContributeDocumentByIdUnity(idUnity: number | undefined, paginator = 1, rate: string) {
    if (!idUnity) {
        return {
            data: ''
        }
    }

    let url = `unidade/${idUnity}/contribuicao/?page=${paginator}`;

    if (rate !== 'Todas') {
        url = `unidade/${idUnity}/contribuicao/avaliacao/${rate}/?page=${paginator}`;
    }

    const { data } = await material_api.get(url);
    return data;
}

async function showContributeDocumentByIdUnity(idContribute: number | undefined) {
    if (!idContribute) {
        return {
            data: ''
        }
    }

    const { data } = await material_api.get(`unidade/1/contribuicao/${idContribute}`);
    return data;
}

async function postRegisterContribute({ titulo, link, descricao, idUnity }: IRegisterContributeProps) {
    if (!idUnity) {
        return {
            data: {}
        }
    }

    const { data } = await material_api.post(`unidade/${idUnity}/contribuicao`, {
        titulo,
        link,
        descricao
    });

    return data;
}

async function postRegisterCommentContribute({ idContribute, comentario }: IRegisterCommentContribute) {
    if (!idContribute) {
        return {
            data: {}
        }
    }

    const { data } = await material_api.post(`unidade/1/contribuicao/${idContribute}/comentario`, {
        comentario
    });

    return data;
}

async function putRegisterRate({ idContribute, avaliacao }: IRegisterRateContribute) {
    if (
        !idContribute ||
        !avaliacao
    ) {
        return {
            data: {}
        }
    }

    const { data } = await material_api.put(`unidade/1/contribuicao/${idContribute}/avaliacao`, {
        avaliacao
    });

    return data;
}

async function getDeepeningKnowledgeByIdUnity(idUnity: number | undefined) {
    if (!idUnity) {
        return {
            data: ''
        }
    }

    const { data } = await material_api.get(`unidade/${idUnity}/aprofundando-conhecimento`);
    return data;
}

async function getRatesByIdUnity(idUnity: number | undefined) {
    if (!idUnity) {
        return {
            data: {}
        }
    }

    const { data } = await material_api.get(`unidade/${idUnity}/avaliacao`);
    return data;
}

async function postRegisterRateUnity({ idUnity, rate, feedback }: IRegisterRateUnity) {
    if (!idUnity) {
        return {
            data: {}
        }
    }

    const { data } = await material_api.post(`unidade/${idUnity}/avaliacao`, {
        avaliacao: rate,
        feedback
    });

    return data;
}

async function getPraticalClassScriptByIdUnity(idUnity: number | undefined) {
    if (!idUnity) {
        return {
            data: {}
        }
    }

    return await material_api.get(`/unidade/${idUnity}/recurso-avaliacao`);
}