import { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import HomeOutlined from "@mui/icons-material/HomeOutlined";
import Menu from "@mui/icons-material/Menu";

import SidebarMobile from "../SidebarMobile";
import BtnNextStep from "./BtnNextStep";

import useDataContext from "context/data.hook";
import { useGetLogin } from "hooksQuerys/useLogin";

import './styles.scss';

export default function Header(){
  const { theme } = useDataContext();
  const access = useGetLogin().data;

  const [openMenuSidebarMobile, setOpenMenuSidebarMobile] = useState(false);

  return (
    <AppBar className="header">
        <Toolbar className="content">
          <Box component="div">
            {
              !!access && (
                <>
                  <IconButton sx={{ display: { xs: 'none', md: 'block' } }}>
                    <HomeOutlined />
                  </IconButton>

                  <IconButton
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    onClick={() => setOpenMenuSidebarMobile(!openMenuSidebarMobile)}
                  >
                    <Menu />
                  </IconButton>
                </>
              )
            }

            {
              theme === 'fal' ? 
                <img src="https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-07.svg" alt="Logo da faculdade 5 de julho branca" />
              : <img src="/uninta.svg" alt="Logo do UNINTA vermelha"/>
            }
            
          </Box>
          
          {
            !!access && <BtnNextStep />
          }
        </Toolbar>

        <SidebarMobile open={openMenuSidebarMobile} setOpen={setOpenMenuSidebarMobile}/>
    </AppBar>
  );
}