import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

import { BoxQuestionProps } from './types';

import './styles.scss';

export default function BoxQuestion({
    idItem,
    numItem,
    statement,
    lengends,
    changeResponses
}: BoxQuestionProps) {
    return (
        <Box className="box_question">
            <Box className="header_question">
                <Typography className="num_item">{numItem}</Typography>
                <Typography>
                    <span title="Campo obrigatório">*</span>
                    {statement}
                </Typography>
            </Box>

            <Box className="dividers" />

            <Box className="body_question">
                {
                    typeof lengends !== 'string' ? (
                        <RadioGroup row onChange={(e) => changeResponses(idItem, e.target.value)}>
                            {
                                Object.entries(lengends).map(([key, values]) => (
                                    <FormControlLabel
                                        key={key}
                                        value={key}
                                        control={<Radio required/>}
                                        label={values as string}
                                    />
                                ))
                            }
                        </RadioGroup>
                    ) : (
                        <TextField
                            fullWidth
                            placeholder="Digite aqui sua resposta..."
                            multiline
                            minRows={4}
                            maxRows={4}
                            onChange={(e) => changeResponses(idItem, e.target.value)}
                        />
                    )
                }
            </Box>
        </Box>
    )
}